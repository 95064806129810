import React from "react";

const PostsCard = ({ images, handleRemovePhoto, openModal }) => {
  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title">Posts</h5>
      </div>
      <div className="card-body pt-0">
        <div className="post-section">
          <label
            htmlFor="postImage"
            className="btn btn-danger mt-3 mb-3"
            style={{
              borderRadius: "5px",
              padding: "5px 10px",
              marginRight: "10px",
              fontSize: "12px",
            }}
            onClick={openModal}
          >
            <i className="fa-solid fa-plus"></i> Post
          </label>

          <div
            className="post-list"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {images && images.length > 0 ? (
              images.map((photo, index) => (
                <div
                  key={index}
                  className="post-item"
                  style={{
                    flex: "0 1 calc(10% - 5px)",
                    margin: "5px",
                    position: "relative",
                  }}
                >
                  <img
                    src={photo.full_image_url} // Use photo.url para exibir a imagem
                    alt={photo.name}
                    className="img-fluid mb-3"
                    style={{
                      objectFit: "cover",
                      width: "100px",
                      height: "100px",
                      borderRadius: "5px",
                    }}
                  />
                  <div className="post-actions">
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={() => handleRemovePhoto(photo.id)}
                    >
                      Remover
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-posts-message">
                <p>Não há imagens para mostrar.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostsCard;
