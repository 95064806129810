import React from 'react'
import '../assets/css/loading.css'
import Finalize_MainIcon from '../assets/img/Finalize_MainIcon.png'
function Loading() {
  return (
    <div className='loading'>
        <img src={process.env.PUBLIC_URL + Finalize_MainIcon} alt="Carregando..." className='imagem'/>
        <p>A carregar...</p>
      
    </div>
  )
}

export default Loading
