import React, { useState } from "react";
import login_logo from "../assets/img/login.png";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import env from "../utils/env";
import Swal from "sweetalert2";
import ToastAlert from "../utils/ToastAlert";
const Login = () => {
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors([]);
    try {
      const response = await fetch(env.base_url + "auth", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(formData),
      });

      let jsonResponse = await response.json();

      if (!response.ok) {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: jsonResponse.errors || "Credenciais incorretas. Por favor, tente novamente.",
        });
        setLoading(false);
        return;
      }

      const { token, data, message } = jsonResponse;

      if (!data) {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: message,
        });
        setLoading(false);
        return;
      }

      login(data);
      localStorage.setItem("token", token);
      setLoading(false);
      if (data.type === "school") {
        ToastAlert({ icon: "success", title: "success", text: data.message });
        navigate("/");
      } else {
        ToastAlert({ icon: "error", title: "Erro", text: data.errors });
      }
      
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Ops! Parece que encontramos um pequeno contratempo. Contacte o administrador do sistema caso o erro persista.",
      });
      setLoading(false);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    
  };
  

  return (
    <div className="main-wrapper login-body">
      <div className="login-wrapper">
        <div className="container">
          <div className="loginbox">
            <div className="login-left">
              <img className="img-fluid" src={login_logo} alt="Logo" />
            </div>
            <div className="login-right">
              <div className="login-right-wrap">
                <h1 className="mb-5">Bem-Vindo</h1>

                

                <form onSubmit={handleLogin}>
                    <ol className="text-danger">
                      {errors &&
                        errors.map((error, index) => {
                          return <li key={index}>{error}</li>;
                        })}
                    </ol>
                  <div className="form-group">
                    <label>
                      Email <span className="login-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="email"
                      type="text"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                    <span className="profile-views">
                      <i className="fas fa-envelope"></i>
                    </span>
                  </div>
                  <div className="form-group">
                    <label>
                      Senha <span className="login-danger">*</span>
                    </label>
                    <input
                      className="form-control pass-input"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                    {showPassword ? (
                            <span 
                              className="profile-views fa-regular fa-eye-slash toggle-password"
                              onClick={() => setShowPassword(false)}
                              style={{ cursor: "pointer" }}
                            />
                          ) : (
                            <span 
                            className="profile-views fa-regular fa-eye toggle-password"
                              onClick={() => setShowPassword(true)}
                              style={{ cursor: "pointer" }}
                            />
                          )}
                  </div>
                  <div className="forgotpass">
                    <div className="remember-me">
                      <label className="custom_check mr-2 mb-0 d-inline-flex remember-me">
                        Remember me
                        <input type="checkbox" name="radio" />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <a href="/forgot-password">Esqueceu a senha?</a>
                  </div>
                  <div className="form-group">
                    <button
                      className="btn btn-danger btn-block"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Carregando..." : "Iniciar Sessão"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
