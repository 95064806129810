import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Footer from '../Components/Footer';
import env from '../utils/env';
import { useAuth } from '../Context/AuthContext';


const Teachers = () => {
    const [teachers, setTeachers] = useState([]);
    const {user} = useAuth();

    useEffect(() => {
        const fetchTeachers = async () => {
            try {
              const response = await fetch(`${env.base_url}schools/${user.school.id}/teachers`, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
              });
              const { data } = await response.json();
             
              setTeachers(data);
              


            } catch (error) {
              console.error("Erro ao buscar os dados dos professores:", error);
            };
        };

        fetchTeachers();


    }, [user]);

  return (
    <div className="page-wrapper">
      <Header />
      <Sidebar />
      <div className="content container-fluid">

        <div className="page-header">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-sub-header">
                <h3 className="page-title">teachers</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><a href="teachers.html">Teacher</a></li>
                  <li className="breadcrumb-item active">All teachers</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="student-group-form">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="form-group">
                <input type="text" className="form-control" placeholder="Search by ID ..." />
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="form-group">
                <input type="text" className="form-control" placeholder="Search by Name ..." />
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="form-group">
                <input type="text" className="form-control" placeholder="Search by Phone ..." />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="search-student-btn">
                <button type="btn" className="btn btn-primary">Search</button>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table comman-shadow">
              <div className="card-body">

                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col">
                      <h3 className="page-title">teachers</h3>
                    </div>
                    <div className="col-auto text-end float-end ms-auto download-grp">
                      <a href="/teachers" className="btn btn-outline-gray me-2 active"><i className="fa-solid fa-list-ul"></i></a>
                      <a href="/teachers-grid" className="btn btn-outline-gray me-2"><i className="fa-solid fa-grip"></i></a>
                      <a href="teachers.html#" className="btn btn-outline-primary me-2"><i className="fas fa-download"></i> Download</a>
                      <a href="/add-employee" className="btn btn-primary"><i className="fas fa-plus"></i></a>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                    <thead className="student-thread">
                      <tr>
                        <th>
                          <div className="form-check check-tables">
                            <input className="form-check-input" type="checkbox" value="something" />
                          </div>
                        </th>
                        <th>ID</th>
                        <th>Nome</th>
                        <th>Cargo</th>
                        <th>Numero de telefone</th>
                        <th>Email</th>
                        <th>Hora de entrada</th>
                        <th>Hora de saida</th>
                        <th className="text-end">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {teachers.map((teacher, index) => (
                        <tr key={index}>
                          <td>
                            <div className="form-check check-tables">
                              <input className="form-check-input" type="checkbox" value="something" />
                            </div>
                          </td>
                          <td>{index + 1}</td>
                          <td>
                            <h2 className="table-avatar">
                              
                              <a href="teacher-details.html">{teacher.full_name}</a>
                            </h2>
                          </td>
                          <td>{teacher.position}</td>
                          <td>{teacher.phone}</td>
                          <td>{teacher.email}</td>
                          <td>{teacher.check_in_time}</td>
                          <td>{teacher.check_out_time}</td>
                          <td className="text-end">
                            <div className="actions">
                              <a href="edit#{teacher.id}" className="btn btn-sm bg-success-light me-2">
                                <i className="fa-regular fa-eye"></i>
                              </a>
                              <a href={`/edit-teacher/${teacher.id}`} className="btn btn-sm bg-danger-light">
                                <i className="fa-regular fa-pen-to-square"></i>
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Teachers;
