import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import Footer from "../Components/Footer";
import env from "../utils/env";
import ToastAlert from "../utils/ToastAlert";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Importa o estilo do Quill
import { useAuth } from "../Context/AuthContext";
const AddClass = () => {
  const [className, setClassName] = useState("");
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);
  const [teachers, setTeachers] = useState([]);
  const [levels, setLevels] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [description, setDescription] = useState("");
  const { user } = useAuth();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const newClass = {
      name: className,
      year: year,
      description: description,
      teacher_id: selectedTeacher,
      school_level_id: selectedLevel,
    };
    console.log(newClass);

    try {
      const response = await fetch(
        `${env.base_url}schools/${user.school.id}/classes`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(newClass),
        }
      );

      const { message, errors } = await response.json();

      if (response.ok) {
        ToastAlert({ icon: "success", title: "Success", text: message });
        setClassName("");
        setYear("");
        setTeachers("");
        setLevels("");
      } else {
        ToastAlert({ icon: "error", title: "Error", text: errors });
      }
    } catch (error) {
      console.error("Error adding class:", error);
    }
  };

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const response = await fetch(
          `${env.base_url}schools/${user.school.id}/teachers`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        const { data } = await response.json();
        console.log(data);
        setTeachers(data);
      } catch (error) {
        console.error("Erro ao buscar os dados dos professores:", error);
      }
    };
    const fetchLevels = async () => {
      try {
        const response = await fetch(
          `${env.base_url}schools/${user.school.id}/levels`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        const { data } = await response.json();
        console.log("Levels", data);
        setLevels(data);
      } catch (error) {
        console.error("Erro ao buscar os dados dos professores:", error);
      }
    };

    fetchTeachers();
    fetchLevels();
  }, [user]);

  const EditorModules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      ["bold", "italic", "underline"],
      [{ align: [] }],
      ["link"],
      ["clean"],
    ],
  };

  return (
    <div className="page-wrapper">
      <Header />
      <Sidebar />
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Adicionar Turma</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="classes.html">Turmas</a>
                </li>
                <li className="breadcrumb-item active">Adicionar Turma</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12">
                      <h5 className="form-title">
                        <span>Informações da Turma</span>
                      </h5>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <label>Nome da Turma</label>
                        <input
                          type="text"
                          className="form-control"
                          value={className}
                          onChange={(e) => setClassName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <label>Ano</label>
                        <input
                          type="number"
                          className="form-control"
                          value={year}
                          onChange={(e) => setYear(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <label>Professor Responsável</label>
                        {teachers.length > 0 ? (
                          <select
                            className="form-control select"
                            value={selectedTeacher}
                            onChange={(e) => setSelectedTeacher(e.target.value)}
                            required
                          >
                            <option value="">Selecione o Professor</option>
                            {teachers.map((teacher) => (
                              <option key={teacher.id} value={teacher.id}>
                                {teacher.full_name}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <p>Nenhum professor encontrado.</p>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <label>Nivel</label>
                        {levels.length > 0 ? (
                          <select
                            className="form-control select"
                            value={selectedLevel}
                            onChange={(e) => setSelectedLevel(e.target.value)}
                            required
                          >
                            <option value="">Selecione o Nivel</option>
                            {levels.map((level) => (
                              <option key={level.id} value={level.id}>
                                {level.name}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <p>Nenhum nivel encontrado.</p>
                        )}
                      </div>
                    </div>
                    <div className="col-12 mb-5">
                      <div className="form-group">
                        <label>Descricao</label>
                        <textarea
                          row="3"
                          col="3"
                          className="form-control"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <button type="submit" className="btn btn-primary">
                        Enviar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AddClass;
