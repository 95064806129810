import React, { useState, useEffect } from 'react';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Footer from '../Components/Footer';

const Subjects = () => {
  const [subject, setSubject] = useState([]);

  useEffect(() => {
    // const fetchsubject = async () => {
    //   try {
    //     const response = await fetch('https://api.example.com/subject'); // Substitua pela URL da sua API
    //     const data = await response.json();
    //     setSubject(data);
    //   } catch (error) {
    //     console.error('Error fetching subject:', error);
    //   }
    // };
    // fetchsubject();

    // Dados estáticos para exemplo enquanto a API não está disponível
   
    const dummyData = [
      { id: 1, name: 'Matematica', professor: 'Professor A' },
      { id: 2, name: 'Linguagem', professor: 'Professor B' },
      { id: 3, name: 'Etica', professor: 'Professor C' },

    ];
    setSubject(dummyData);
    
  }, []);

  return (
    <div className="page-wrapper">
        <Header/>
        <Sidebar/>
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Disciplinas</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item"><a href="index.html">Dashboard</a></li>
                <li className="breadcrumb-item active">Disciplinas</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table">
              <div className="card-body">
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col">
                      <h3 className="page-title">Displina</h3>
                    </div>
                    <div className="col-auto text-end float-end ms-auto download-grp">
                      <a href="/#" className="btn btn-outline-primary me-2"><i className="fas fa-download"></i> Download</a>
                      <a href="/add-subject" className="btn btn-primary"><i className="fas fa-plus"></i></a>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                    <thead className="student-thread">
                      <tr>
                        <th>#</th>
                        <th>Disciplina</th>
                        <th>Professor Responsável</th>
                        <th className="text-end">Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subject.map((subject, index) => (
                        <tr key={subject.id}>
                          <td>{index + 1}</td>
                          <td><h2>{subject.name}</h2></td>
                          <td>{subject.professor}</td>
                          <td className="text-end">
                            <div className="actions">
                              <a href="/subject-details" className="btn btn-sm bg-success-light me-2">
                                <i className="fa-regular fa-eye"></i>
                              </a>
                              <a href="/edit-subject" className="btn btn-sm bg-danger-light">
                                <i className="fa-regular fa-pen-to-square"></i>
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
};

export default Subjects;
