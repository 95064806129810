import React, { useState } from 'react';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Footer from '../Components/Footer';

const AddStudent = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    dob: '',
    roll: '',
    bloodGroup: '',
    religion: '',
    email: '',
    studentClass: '',
    section: '',
    admissionId: '',
    phone: '',
    photo: null
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form data:', formData);
    // Aqui você pode adicionar a lógica para enviar os dados do formulário ao servidor
  };

  return (
    <div className="page-wrapper">
      <Header />
      <Sidebar />
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <div className="page-sub-header">
                <h3 className="page-title">Add Students</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/#">Student</a></li>
                  <li className="breadcrumb-item active">Add Students</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card comman-shadow">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12">
                      <h5 className="form-title student-info">Student Information <span><a href="/"><i className="feather-more-vertical"></i></a></span></h5>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>First Name <span className="login-danger">*</span></label>
                        <input className="form-control" type="text" placeholder="Enter First Name" name="firstName" value={formData.firstName} onChange={handleChange} required />
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>Last Name <span className="login-danger">*</span></label>
                        <input className="form-control" type="text" placeholder="Enter Last Name" name="lastName" value={formData.lastName} onChange={handleChange} required />
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>Gender <span className="login-danger">*</span></label>
                        <select className="form-control select" name="gender" value={formData.gender} onChange={handleChange} required>
                          <option>Select Gender</option>
                          <option value="Female">Female</option>
                          <option value="Male">Male</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms calendar-icon">
                        <label>Date Of Birth <span className="login-danger">*</span></label>
                        <input className="form-control datetimepicker" type="date" placeholder="DD-MM-YYYY" name="dob" value={formData.dob} onChange={handleChange} required />
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>Roll </label>
                        <input className="form-control" type="text" placeholder="Enter Roll Number" name="roll" value={formData.roll} onChange={handleChange} />
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>Blood Group <span className="login-danger">*</span></label>
                        <select className="form-control select" name="bloodGroup" value={formData.bloodGroup} onChange={handleChange} required>
                          <option>Please Select Group</option>
                          <option value="B+">B+</option>
                          <option value="A+">A+</option>
                          <option value="O+">O+</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>Religion <span className="login-danger">*</span></label>
                        <select className="form-control select" name="religion" value={formData.religion} onChange={handleChange} required>
                          <option>Please Select Religion</option>
                          <option value="Hindu">Hindu</option>
                          <option value="Christian">Christian</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>E-Mail <span className="login-danger">*</span></label>
                        <input className="form-control" type="email" placeholder="Enter Email Address" name="email" value={formData.email} onChange={handleChange} required />
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>Class <span className="login-danger">*</span></label>
                        <select className="form-control select" name="studentClass" value={formData.studentClass} onChange={handleChange} required>
                          <option>Please Select Class</option>
                          <option value="12">12</option>
                          <option value="11">11</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>Section <span className="login-danger">*</span></label>
                        <select className="form-control select" name="section" value={formData.section} onChange={handleChange} required>
                          <option>Please Select Section</option>
                          <option value="B">B</option>
                          <option value="A">A</option>
                          <option value="C">C</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>Admission ID </label>
                        <input className="form-control" type="text" placeholder="Enter Admission ID" name="admissionId" value={formData.admissionId} onChange={handleChange} />
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>Phone </label>
                        <input className="form-control" type="text" placeholder="Enter Phone Number" name="phone" value={formData.phone} onChange={handleChange} />
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group students-up-files">
                        <label>Upload Student Photo (150px X 150px)</label>
                        <div className="uplod">
                          <label className="file-upload image-upbtn mb-0">
                            Choose File <input type="file" name="photo" onChange={handleChange} />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="student-submit">
                        <button type="submit" className="btn btn-primary">Submit</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AddStudent;
