import React, { useState, useEffect } from "react";
import ToastAlert from "../utils/ToastAlert";
import { useAuth } from "../Context/AuthContext";
import env from "../utils/env";

const AddressDetailsCard = () => {
  const { user } = useAuth();
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [selectedProvinceId, setSelectedProvinceId] = useState("");
  const [selectedDistrictId, setSelectedDistrictId] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    full_logo_url: "",
    full_cover_url: "",
    province: { name: "", id: "" },
    district: { name: "", id: "" },
    phone: "",
    address: "",
    city: "",
    description: "",
    info_email: "",
    support_email: "",
    open_time: "",
    operating_hours: "",
    close_time: "",
    capacity: "",
    classrooms: "",
    education_type: "",
    age_range: "",
    fees: "",
    enrollment_fee: "",
    enrollment_open: "",
    enrollment_contact: "",
    user_id: user.id,
    pictures: [],
    id: "",
    logo: "",
  });

  const handleSelectProvince = (e) => {
    const provinceId = e.target.value;
    setSelectedProvinceId(provinceId);
    setFormData((prevState) => ({
      ...prevState,
      province_id: provinceId,  // Atualiza corretamente o ID da província
      district: { name: "", id: "" },  // Reseta o distrito quando uma nova província é selecionada
    }));
  
    fetchDistrictsData(provinceId); // Fetch districts based on selected province ID
  };
  

  const handleSelectDistrict = (e) => {
    const districtId = e.target.value;
    setSelectedDistrictId(districtId);
    setFormData((prevState) => ({
      ...prevState,
      district_id: districtId,
    }));
  };

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const fetchAddressData = async () => {
    try {
      const response = await fetch(`${env.base_url}schools/${user.school.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Erro: ${response.status}`);
      }

      const { data } = await response.json();

      setFormData({
        ...formData,
        name: data.name || "",
        full_logo_url: data.full_logo_url || "",
        full_cover_url: data.full_cover_url || "",
        address: data.address || "",
        city: data.city || "",
        province: data.province || { name: "", id: "" },
        district: data.district || { name: "", id: "" },
        phone: data.phone || "",
        province_id: data.province ? data.province.id : "", // Verifica se data.province existe antes de acessar id
        district_id: data.district ? data.district.id : "", // Verifica se data.district existe antes de acessar id
        open_time: data.open_time || "",
        close_time: data.close_time || "",
        operating_hours: data.operating_hours || "",
        capacity: data.capacity || "",
        description: data.description || "",
        info_email: data.info_email || "",
        support_email: data.support_email || "",
        classrooms: data.classrooms || "",
        education_type: data.education_type || "",
        duration: data.duration || "",
        photos: data.pictures || [],
        enrollment_fee: data.enrollment_fee || "",
        enrollment_open: data.enrollment_open || "",
        enrollment_contact: data.enrollment_contact || "",
        user_id: user.id,
        email: data.email || "",
        id: data.id || "",
        logo: "",
      });

      // Set the selected province and district IDs if available
      setSelectedProvinceId(data.province ? data.province.id : "");
      setSelectedDistrictId(data.district ? data.district.id : "");

      // Fetch districts for the current province ID
      if (data.province && data.province.id) {
        fetchDistrictsData(data.province.id);
      }
    } catch (error) {
      console.error("Erro ao buscar dados do endereço:", error);
    }
  };

  const updateAddress = async (e) => {
  e.preventDefault();
  setLoading(true);

  console.log('FormData being sent:', formData); // Verifica os dados antes de enviar

  try {
    const response = await fetch(
      `${env.base_url}schools/${user.school.id}/address`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(formData),
      }
    );

    const { message, errors } = await response.json();
    if (response.ok) {
      ToastAlert({ icon: "success", title: "Sucesso", text: message });
      fetchAddressData(); // Fetch updated data
    } else {
      ToastAlert({ icon: "error", title: "Erro", text: errors });
    }
  } catch (error) {
    console.error("Erro ao atualizar endereço:", error);
    ToastAlert({
      msg: "Erro ao atualizar o endereço.",
      msgType: "error",
      timeout: 3000,
    });
  } finally {
    setLoading(false);
  }
};


  const fetchProvincesData = async () => {
    try {
      const response = await fetch(`${env.base_url}provinces`, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Erro: ${response.status}`);
      }

      const { data } = await response.json();

      setProvinces(data);
    } catch (error) {
      console.error("Erro ao buscar dados das províncias:", error);
    }
  };

  const fetchDistrictsData = async (provinceId) => {
    try {
      const response = await fetch(
        `${env.base_url}districts?province_id=${provinceId}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Erro: ${response.status}`);
      }

      const { data } = await response.json();
      setDistricts(data);
    } catch (error) {
      console.error("Erro ao buscar dados dos distritos:", error);
    }
  };

  useEffect(() => {
    fetchProvincesData();
    fetchAddressData();
  }, []);

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title">Detalhes do Endereço</h5>
      </div>
      <div className="card-body pt-0">
        <form onSubmit={updateAddress}>
          <div className="settings-form">
            <div className="form-group">
              <label>Endereço Completo</label>
              <input
                type="text"
                className="form-control"
                placeholder="Insira o endereço completo"
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-md-6">
                  <label>Província</label>
                  <select
                    className="form-control"
                    name="province"
                    value={selectedProvinceId}
                    onChange={handleSelectProvince}
                  >
                    {provinces.map((province) => (
                      <option key={province.id} value={province.id}>
                        {province.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6">
                  <label>Distrito</label>
                  <select
                    className="form-control"
                    name="district"
                    value={selectedDistrictId}
                    onChange={handleSelectDistrict}
                    disabled={!selectedProvinceId}
                  >
                    {formData.hasOwnProperty("district") ? (
                      <option value={formData.district.id}>
                        {" "}
                        {formData.district.name}
                      </option>
                    ) : (
                      <option value="">Escolher distrito</option>
                    )}
                    {districts.map((district) => (
                      <option key={district.id} value={district.id}>
                        {district.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-danger"
                disabled={loading}
              >
                {loading ? "Atualizando..." : "Atualizar"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddressDetailsCard;
