import React from "react";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import Footer from "../Components/Footer";
import invoices01 from "../assets/img/icons/invoices-icon1.svg";
import invoices02 from "../assets/img/icons/invoices-icon2.svg";
import invoices03 from "../assets/img/icons/invoices-icon3.svg";
import invoices04 from "../assets/img/icons/invoices-icon4.svg";  
import invoices05 from "../assets/img/icons/invoices-icon5.png";  
import avatar_04 from '../assets/img/profiles/avatar-04.jpg';
import avatar_06 from '../assets/img/profiles/avatar-06.jpg';
import avatar_08 from '../assets/img/profiles/avatar-08.jpg';
import avatar_11 from '../assets/img/profiles/avatar-11.jpg';
import avatar_10 from '../assets/img/profiles/avatar-10.jpg';
const InvoicesPage = () => {
    const invoices = [
        {
          id: 'IN093439#@09',
          category: 'Advertising',
          createdOn: '16 Mar 2022',
          invoiceTo: {
            name: 'Barbara Moore',
            avatar: avatar_04 // Caminho para a imagem do avatar
          },
          amount: '$1,54,220',
          dueDate: '23 Mar 2022',
          status: 'Paid'
        },
        {
          id: 'IN093439#@10',
          category: 'Food',
          createdOn: '14 Mar 2022',
          invoiceTo: {
            name: 'Karlene Chaidez',
            avatar: avatar_06
          },
          amount: '$1,222',
          dueDate: '18 Mar 2022',
          status: 'Overdue'
        },
        {
          id: 'IN093439#@11',
          category: 'Marketing',
          createdOn: '7 Mar 2022',
          invoiceTo: {
            name: 'Russell Copeland',
            avatar: avatar_08
          },
          amount: '$3,470',
          dueDate: '10 Mar 2022',
          status: 'Cancelled'
        },
        {
          id: 'IN093439#@12',
          category: 'Repairs',
          createdOn: '24 Mar 2022',
          invoiceTo: {
            name: 'Joseph Collins',
            avatar: avatar_10
          },
          amount: '$8,265',
          dueDate: '30 Mar 2022',
          status: 'Paid'
        },
        {
          id: 'IN093439#@13',
          category: 'Software',
          createdOn: '17 Mar 2022',
          invoiceTo: {
            name: 'Jennifer Floyd',
            avatar: avatar_11
          },
          amount: '$5,200',
          dueDate: '20 Mar 2022',
          status: 'Overdue'
        }
      ];
  return (
    <div className="page-wrapper">
      <Header />
      <Sidebar />
      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Invoices</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="index.html">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">Invoices</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Main Tabs */}
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col"></div>
            <div className="col-auto">
              <a href="invoices.html" className="invoices-links active">
                <i className="fa-solid fa-list-ul"></i>
              </a>
              <a href="invoice-grid.html" className="invoices-links">
                <i className="fa-solid fa-grip"></i>
              </a>
            </div>
          </div>
        </div>

        {/* Filters Section */}
        <div className="card report-card">
          <div className="card-body pb-0">
            <div className="row">
              <div className="col-md-12">
                <ul className="app-listing">
                  <li>
                    <div className="multipleSelection">
                      <div className="selectBox">
                        <p className="mb-0">
                          <i className="fas fa-user-plus me-1 select-icon"></i>{" "}
                          Select User
                        </p>
                        <span className="down-icon">
                          <i className="fas fa-chevron-down"></i>
                        </span>
                      </div>
                      <div id="checkBoxes">
                        <form action="invoices.html#">
                          <p className="checkbox-title">Customer Search</p>
                          <div className="form-custom">
                            <input
                              type="text"
                              className="form-control bg-grey"
                              placeholder="Enter Customer Name"
                            />
                          </div>
                          <div className="selectBox-cont">
                            <label className="custom_check w-100">
                              <input type="checkbox" name="username" />
                              <span className="checkmark"></span> Brian Johnson
                            </label>
                            {/* Outros itens checkbox aqui */}
                          </div>
                          <button
                            type="submit"
                            className="btn w-100 btn-primary"
                          >
                            Apply
                          </button>
                          <button type="reset" className="btn w-100 btn-grey">
                            Reset
                          </button>
                        </form>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="multipleSelection">
                      <div className="selectBox">
                        <p className="mb-0">
                          <i className="fas fa-calendar me-1 select-icon"></i>{" "}
                          Select Date
                        </p>
                        <span className="down-icon">
                          <i className="fas fa-chevron-down"></i>
                        </span>
                      </div>
                      <div id="checkBoxes">
                        <form action="invoices.html#">
                          <p className="checkbox-title">Customer Search</p>
                          <div className="form-custom">
                            <input
                              type="text"
                              className="form-control bg-grey"
                              placeholder="Enter Customer Name"
                            />
                          </div>
                          <div className="selectBox-cont">
                            <label className="custom_check w-100">
                              <input type="checkbox" name="username" />
                              <span className="checkmark"></span> Brian Johnson
                            </label>
                            {/* Outros itens checkbox aqui */}
                          </div>
                          <button
                            type="submit"
                            className="btn w-100 btn-primary"
                          >
                            Apply
                          </button>
                          <button type="reset" className="btn w-100 btn-grey">
                            Reset
                          </button>
                        </form>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="multipleSelection">
                      <div className="selectBox">
                        <p className="mb-0">
                          <i className="fas fa-book-open me-1 select-icon"></i>{" "}
                          Select Status
                        </p>
                        <span className="down-icon">
                          <i className="fas fa-chevron-down"></i>
                        </span>
                      </div>
                      <div id="checkBoxes">
                        <form action="invoices.html#">
                          <p className="checkbox-title">Customer Search</p>
                          <div className="form-custom">
                            <input
                              type="text"
                              className="form-control bg-grey"
                              placeholder="Enter Customer Name"
                            />
                          </div>
                          <div className="selectBox-cont">
                            <label className="custom_check w-100">
                              <input type="checkbox" name="username" />
                              <span className="checkmark"></span> Brian Johnson
                            </label>
                            {/* Outros itens checkbox aqui */}
                          </div>
                          <button
                            type="submit"
                            className="btn w-100 btn-primary"
                          >
                            Apply
                          </button>
                          <button type="reset" className="btn w-100 btn-grey">
                            Reset
                          </button>
                        </form>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="multipleSelection">
                      <div className="selectBox">
                        <p className="mb-0">
                          <i className="fas fa-bookmark me-1 select-icon"></i>{" "}
                          By category
                        </p>
                        <span className="down-icon">
                          <i className="fas fa-chevron-down"></i>
                        </span>
                      </div>
                      <div id="checkBoxes">
                        <form action="invoices.html#">
                          <p className="checkbox-title">Customer Search</p>
                          <div className="form-custom">
                            <input
                              type="text"
                              className="form-control bg-grey"
                              placeholder="Enter Customer Name"
                            />
                          </div>
                          <div className="selectBox-cont">
                            <label className="custom_check w-100">
                              <input type="checkbox" name="username" />
                              <span className="checkmark"></span> Brian Johnson
                            </label>
                            {/* Outros itens checkbox aqui */}
                          </div>
                          <button
                            type="submit"
                            className="btn w-100 btn-primary"
                          >
                            Apply
                          </button>
                          <button type="reset" className="btn w-100 btn-grey">
                            Reset
                          </button>
                        </form>
                      </div>
                    </div>
                  </li>

                  <li>
                      <div className="report-btn">
                        <a href="/#" className="btn">
                          <img src={invoices05} alt="" className="me-2" /> Generate report
                        </a>
                      </div>
                    </li>

                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Invoices Main Tabs */}
        <div className="card invoices-tabs-card border-0">
          <div className="card-body card-body pt-0 pb-0">
            <div className="invoices-main-tabs">
              <div className="row align-items-center">
                <div className="col-lg-8 col-md-8">
                  <div className="invoices-tabs">
                    <ul>
                      <li>
                        <a href="invoices.html" className="active">
                          All Invoice
                        </a>
                      </li>
                      <li>
                        <a href="invoices-paid.html">Paid</a>
                      </li>
                      <li>
                        <a href="invoices-overdue.html">Overdue</a>
                      </li>
                      <li>
                        <a href="invoices-draft.html">Draft</a>
                      </li>
                      <li>
                        <a href="invoices-recurring.html">Recurring</a>
                      </li>
                      <li>
                        <a href="invoices-cancelled.html">Cancelled</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="invoices-settings-btn">
                    <a
                      href="invoices-settings.html"
                      className="invoices-settings-icon"
                    >
                      <i className="fa-solid fa-gear"></i>
                    </a>
                    <a href="add-invoice.html" className="btn">
                      <i className="fa-solid fa-circle-plus me-2"></i> New
                      Invoice
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Widgets Section */}
        <div className="row">
          <div className="col-xl-3 col-sm-6 col-12">
            <div className="card inovices-card">
              <div className="card-body">
                <div className="inovices-widget-header">
                  <span className="inovices-widget-icon">
                    <img src={invoices01} alt="" />
                  </span>
                  <div className="inovices-dash-count">
                    <div className="inovices-amount">$8,78,797</div>
                  </div>
                </div>
                <p className="inovices-all">
                  All Invoices <span>50</span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 col-12">
            <div class="card inovices-card">
              <div class="card-body">
                <div class="inovices-widget-header">
                  <span class="inovices-widget-icon">
                    <img src={invoices02} alt="" />
                  </span>
                  <div class="inovices-dash-count">
                    <div class="inovices-amount">$4,5884</div>
                  </div>
                </div>
                <p class="inovices-all">
                  Paid Invoices <span>60</span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 col-12">
            <div class="card inovices-card">
              <div class="card-body">
                <div class="inovices-widget-header">
                  <span class="inovices-widget-icon">
                    <img src={invoices03} alt="" />
                  </span>
                  <div class="inovices-dash-count">
                    <div class="inovices-amount">$2,05,545</div>
                  </div>
                </div>
                <p class="inovices-all">
                  Unpaid Invoices <span>70</span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 col-12">
            <div class="card inovices-card">
              <div class="card-body">
                <div class="inovices-widget-header">
                  <span class="inovices-widget-icon">
                    <img src={invoices04} alt="" />
                  </span>
                  <div class="inovices-dash-count">
                    <div class="inovices-amount">$8,8,797</div>
                  </div>
                </div>
                <p class="inovices-all">
                  Cancelled Invoices <span>80</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Invoice Table */}
        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-striped table-hover datatable">
                    <thead className="thead-light">
                      <tr>
                        <th>Invoice ID</th>
                        <th>Category</th>
                        <th>Created on</th>
                        <th>Invoice to</th>
                        <th>Amount</th>
                        <th>Due date</th>
                        <th>Status</th>
                        <th className="text-end">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Mapeando os dados das faturas para linhas da tabela */}
                      {invoices.map((invoice, index) => (
                        <tr key={index}>
                          <td>
                            <label className="custom_check">
                              <input type="checkbox" name="invoice" />
                              <span className="checkmark"></span>
                            </label>
                            <a href="view-invoice.html" className="invoice-link">{invoice.id}</a>
                          </td>
                          <td>{invoice.category}</td>
                          <td>{invoice.createdOn}</td>
                          <td>
                            <h2 className="table-avatar">
                              <a href="profile.html"><img className="avatar avatar-sm me-2 avatar-img rounded-circle" src={invoice.invoiceTo.avatar} alt="UserImage" /> {invoice.invoiceTo.name}</a>
                            </h2>
                          </td>
                          <td className="text-primary">{invoice.amount}</td>
                          <td>{invoice.dueDate}</td>
                          <td>
                            <span className={`badge ${invoice.status === 'Paid' ? 'bg-success-light' : invoice.status === 'Overdue' ? 'bg-danger-light' : 'bg-primary-light'}`}>{invoice.status}</span>
                          </td>
                          <td className="text-end">
                            <div className="dropdown dropdown-action">
                              <a href="invoices.html#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-ellipsis-v"></i></a>
                              <div className="dropdown-menu dropdown-menu-end">
                                <a className="dropdown-item" href="edit-invoice.html"><i className="far fa-edit me-2"></i>Edit</a>
                                <a className="dropdown-item" href="view-invoice.html"><i className="far fa-eye me-2"></i>View</a>
                                <a className="dropdown-item" href="/#"><i className="far fa-trash-alt me-2"></i>Delete</a>
                                <a className="dropdown-item" href="/#"><i className="far fa-check-circle me-2"></i>Mark as sent</a>
                                <a className="dropdown-item" href="/#"><i className="far fa-paper-plane me-2"></i>Send Invoice</a>
                                <a className="dropdown-item" href="/#"><i className="far fa-copy me-2"></i>Clone Invoice</a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default InvoicesPage;
