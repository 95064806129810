import Swal from "sweetalert2";

export default function ToastAlert({title, text, icon}) {
    Swal.fire({
        toast: true,
        width: 400,
        background: "#f8f9fa",
        // background:'rgba(0, 174, 255, .4)',
        position: "bottom-end",
        timer: 15000,
        timerProgressBar: true,
        showConfirmButton: false,
        icon: icon??"success",
        title: title,
        html: text,
      });
}