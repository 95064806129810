import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import env from "../utils/env";
import { useAuth } from "../Context/AuthContext";
import ProfileCard from "../Components/ProfileCard";
import SchoolDetailsCard from "../Components/SchoolDetailsCard";
import AddressDetailsCard from "../Components/AddressDetailsCard";
import Packages from "../Components/Packages";

const Settings = () => {
  const { user } = useAuth();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    full_logo_url: "",
    full_cover_url: "",
    phone: "",
    address: "",
    city: "",
    description: "",
    province: { name: "", id: "" },
    district: { name: "", id: "" },
    info_email: "",
    support_email: "",
    open_time: "",
    close_time: "",
    operating_hours: "",
    capacity: "",
    duration: "",
    classrooms: "",
    education_type: "",
    age_range: "",
    enrollment_fee: "",
    enrollment_contact: "",
    user_id: user.id,
    pictures: [],
    id: "",
    logo: "",
  });

  useEffect(() => {
    // Função para buscar os dados do centro infantil

    const fetchData = async () => {
      try {
        const response = await fetch(
          env.base_url + `schools/${user.school.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        const { data } = await response.json();
        setFormData({
          name: data.name || "",
          full_logo_url: data.full_logo_url || "",
          full_cover_url: data.full_cover_url || "",
          address: data.address || "",
          city: data.city || "",
          provinces: data.provinces || "",
          phone: data.phone || "",
          province: data.province || { name: "", id: "" },
          district: data.district || { name: "", id: "" },
          country: data.country || "",
          open_time: data.open_time || "",
          close_time: data.close_time || "",
          operating_hours: data.operating_hours || "",
          capacity: data.capacity || "",
          description: data.description || "",
          info_email: data.info_email || "",
          support_email: data.support_email || "",
          classrooms: data.classrooms || "",
          education_type: data.education_type || "",
          duration: data.duration || "",
          districts: data.districts || "",
          photos: data.pictures || [],
          enrollment_fee: data.enrollment_fee || "",
          enrollment_open: data.enrollment_open || "",
          enrollment_contact: data.enrollment_contact || "",
          user_id: user.id,
          email: data.email || "",
          id: data.id || "",
          logo: "",
        });
      } catch (error) {
        console.error("Erro ao buscar os dados:", error);
      }
    };

    fetchData();
  }, [user.id, user.school.id]);

  return (
    <div className="main-wrapper">
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-6">
              <ProfileCard />
            </div>
            <div className="col-md-6">
              <SchoolDetailsCard />
              <AddressDetailsCard />
              
            </div>
            <div className="col-md-12">
            <Packages />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
