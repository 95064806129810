import React from "react";
import "../assets/css/PageNotFound.css"; // Supondo que você tenha um arquivo CSS separado para estilos

const ErrorPage = () => {
  return (
    <div className="page">
      <a
        className="codepen"
        href="https://codepen.io/uiswarup/full/vYPxywO"
        target="_blank"
        rel="noopener noreferrer"
      >
        <header className="top-header">{/* Cabeçalho */}</header>

        {/* Partículas de poeira */}
        <div>
          <div className="starsec"></div>
          <div className="starthird"></div>
          <div className="starfourth"></div>
          <div className="starfifth"></div>
        </div>
        {/* Fim das partículas de poeira */}

        <div className="lamp__wrap">
          <div className="lamp">
            <div className="cable"></div>
            <div className="cover"></div>
            <div className="in-cover">
              <div className="bulb"></div>
            </div>
            <div className="light"></div>
          </div>
        </div>
        {/* Fim da Lâmpada */}

        <section className="error">
          {/* Conteúdo */}
          <div className="error__content">
            <div className="error__message message">
              <h1 className="message__title">404</h1>
              <h1 className="message__title">Página não encontrada</h1>
              <p className="message__text">
                A página que você queria não pôde ser encontrada. Às vezes, os
                melhores lugares são aqueles que não estávamos procurando.
                Explore outras partes do site!
              </p>
            </div>
            <div className="error__nav e-nav">
              <a href="/" rel="noopener noreferrer" className="e-nav__link">
                {/* Link para navegação */}
              </a>
            </div>
          </div>
          {/* Fim do Conteúdo */}
        </section>
      </a>
    </div>
  );
};

export default ErrorPage;
