import React from 'react';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Dashboard from '../Components/Dashboard';

// import { Container, Row, Col } from 'react-bootstrap';

const HomePage = () => {
  return (
    <div className="main-wrapper">
      <Header/>
      <Sidebar/>
      <Dashboard/>
    </div>
  );
};

export default HomePage;
