import React, { useState } from 'react'
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Footer from '../Components/Footer';

const AddFeesForm = () => {
  // Estados para os campos do formulário
  const [feesId, setFeesId] = useState('');
  const [feesType, setFeesType] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [feesAmount, setFeesAmount] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // Opções para o campo Fees Type
  const feesTypeOptions = [
    { value: '', label: 'Select Type' },
    { value: 'Class Test', label: 'Class Test' },
    { value: 'Exam Fees', label: 'Exam Fees' },
    { value: 'Hostel Fees', label: 'Hostel Fees' },
    { value: 'Transport Fees', label: 'Transport Fees' },
    { value: 'Mess Fees', label: 'Mess Fees' }
  ];

  // Opções para o campo Class
  const classOptions = [
    { value: '', label: 'Select Class' },
    { value: 'LKG', label: 'LKG' },
    { value: 'UKG', label: 'UKG' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' }
  ];

  // Função para lidar com a submissão do formulário
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Preparar os dados do formulário para envio
    const formData = {
      feesId,
      feesType,
      selectedClass,
      feesAmount,
      startDate,
      endDate
    };

    try {
      // Aqui a lógica para enviar formData para API
      console.log('Form data to be sent:', formData);

      // Limpar o formulário após a submissão bem-sucedida (opcional)
      setFeesId('');
      setFeesType('');
      setSelectedClass('');
      setFeesAmount('');
      setStartDate('');
      setEndDate('');

    } catch (error) {
      console.error('Error submitting the form:', error);
      // Aqui lógica para lidar com erros de envio
    }
  };

  return (
    <div className="page-wrapper">
        <Header/>
        <Sidebar/>
      <div className="content container-fluid">

        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Add Fees</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item"><a href="fees.html">Fees</a></li>
                <li className="breadcrumb-item active">Add Fees</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12">
                      <h5 className="form-title"><span>Fees Information</span></h5>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <label>Fees ID</label>
                        <input
                          type="text"
                          className="form-control"
                          value={feesId}
                          onChange={(e) => setFeesId(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <label>Fees Type</label>
                        <select
                          className="form-control"
                          value={feesType}
                          onChange={(e) => setFeesType(e.target.value)}
                          required
                        >
                          {feesTypeOptions.map(option => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <label>Class</label>
                        <select
                          className="form-control"
                          value={selectedClass}
                          onChange={(e) => setSelectedClass(e.target.value)}
                          required
                        >
                          {classOptions.map(option => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <label>Fees Amount</label>
                        <input
                          type="text"
                          className="form-control"
                          value={feesAmount}
                          onChange={(e) => setFeesAmount(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <label>Start Date</label>
                        <input
                          type="date"
                          className="form-control"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <label>End Date</label>
                        <input
                          type="date"
                          className="form-control"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
}

export default AddFeesForm;
