import React, { useState, useEffect } from "react";
import ToastAlert  from "../utils/ToastAlert"; 
import { useAuth } from "../Context/AuthContext"; 
import env from "../utils/env"; 
import ReactQuill from 'react-quill'; 
import 'react-quill/dist/quill.snow.css'; 

const ProfileCard = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    full_logo_url: "",
    full_cover_url: "",
    phone: "",
    address: "",
    city: "",
    description: "",
    province: { name: "", id: "" },
    district: { name: "", id: "" },
    info_email: "",
    support_email: "",
    open_time: "",
    close_time: "",
    capacity: "",
    classrooms: "",
    duration: "",
    education_type: "",
    enrollment_contact: "",
    user_id: user.id,
    pictures: [],
    id: "",
    logo: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDescriptionChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      description: value,
    }));
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        logo: file,
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevData) => ({
          ...prevData,
          full_logo_url: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
      `${env.base_url}schools/${user.school.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const {data} = await response.json();
      
      setFormData({
        name: data.name || "",
      full_logo_url: data.full_logo_url || "",
      full_cover_url: data.full_cover_url || "",
      address: data.address || "",
      city: data.city || "",
      provinces: data.provinces || "",
      phone: data.phone || "",
      province: data.province || { name: '', id: '' },
      district: data.district || { name: '', id: '' },
      country: data.country || "",
      open_time: data.open_time || "",
      close_time: data.close_time || "",
      operating_hours: data.operating_hours || "",
      age_range: data.age_range || "",
      capacity: data.capacity || "",
      description: data.description || "",
      info_email: data.info_email || "",
      support_email: data.support_email || "",
      classrooms: data.classrooms || "",
      education_type: data.education_type || "",
      duration: data.duration || "",
      districts: data.districts || "",
      photos: data.pictures || [],
      enrollment_fee: data.enrollment_fee || "",
      enrollment_open: data.enrollment_open || "",

      enrollment_contact: data.enrollment_contact || "",
      user_id: user.id,
      email: data.email || "",
      id: data.id || "",
      logo: "",

      });
    } catch (error) {
      console.error("Erro ao buscar dados do perfil:", error);
    }
  };
  const uploadLogo = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("logo", formData.logo);

      const response = await fetch(
        `${env.base_url}schools/logo/${formData.id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
          },
          body: formDataToSend,
        }
      );


      const { message, errors } = await response.json();
      if (response.ok) {
        ToastAlert({ icon: "success", title: "success", text: message });
      } else {
        ToastAlert({ icon: "error", title: "error", text: errors });
      }
    } catch (error) {
      console.error("Erro ao atualizar endereço:", error);
      ToastAlert({
        msg: "Erro ao atualizar o endereço.",
        msgType: "error",
        timeout: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const updateProfile = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    setLoading(true);

    try {
       let logoUrl = formData.full_logo_url;

      if (formData.logo) {
        
        const uploadedLogoUrl = await uploadLogo();
        if (uploadedLogoUrl) {
          logoUrl = uploadedLogoUrl;
        } else {
          setLoading(false);
          return;
        }
      }

      const updateData = {
        ...formData,
        full_logo_url: logoUrl,
        logo: undefined,
      };

      const response = await fetch(`${env.base_url}schools/${user.school.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
        body: JSON.stringify(updateData),
      });

      const { message, errors } = await response.json();

      if (response.ok) {
        ToastAlert({ icon: "success", title: "success", text: message });
        setLoading(false);
      } else {
        ToastAlert({ icon: "error", title: "success", text: errors });
        setLoading(false);
      }
    } catch (error) {
      console.error("Erro ao enviar os dados:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title">Perfil Centro Infantil</h5>
      </div>
      <div className="card-body pt-0">
        <form onSubmit={updateProfile}>
          <div className="settings-form">
            <div className="d-flex justify-content-center">
              <img
                src={formData.full_logo_url}
                alt={formData.name}
                className="img-fluid"
                style={{
                  objectFit: "cover",
                  width: "150px",
                  height: "150px",
                  borderRadius: "100px",
                  border: "1px solid #ccc",
                }}
              />
            </div>
            <div className="d-flex justify-content-center mt-2">
              <label
                htmlFor="logo"
                className="btn"
                style={{
                  backgroundColor: "red",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <i className="fa-solid fa-camera"></i> Mudar Logo
              </label>
              <input
                type="file"
                id="logo"
                name="logo"
                accept="image/*"
                onChange={handleLogoChange}
                style={{ display: "none" }}
              />
            </div>
            <div className="form-group mt-5">
              <label>
                Nome do Centro Infantil{" "}
                <span className="star-red">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Digite Nome do Centro Infantil"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="text"
                className="form-control"
                placeholder="Insira o email"
                name="email"
                value={formData.email}
                disabled
              />
            </div>
            <div className="form-group">
              <label>Telefone</label>
              <input
                type="text"
                className="form-control"
                placeholder="Insira o telefone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Descrição dos Serviços</label>
              <ReactQuill
              theme="snow"
              value={formData.description}
              onChange={handleDescriptionChange}
            />
            </div>
            <div className="form-group ">
              <div className="settings-btns">
                <button
                  type="submit"
                  className="btn btn-danger"
                  disabled={loading}
                  style={{ marginRight: "10px" }}
                >
                  {loading ? "Atualizando..." : "Atualizar"}
                </button>
                
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileCard;
