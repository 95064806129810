import React from 'react';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Footer from '../Components/Footer';
import { Button } from 'react-bootstrap';
const FeesTable = () => {
  // Dados simulados para a tabela
  const feesData = [
    {
      id: 1,
      name: 'Luis Ribeiro',
      amount: '$345',
      payment_date: '23/04/2020',
      payment_method: 'Deposito bancario',
      status: 'Aprovado' // Status adicionado
    },
    {
      id: 2,
      name: 'Luis Ribeiro Jr',
      amount: '$255',
      payment_date: '23/04/2020',
      payment_method: 'Mpesa',
      status: 'Rejeitado' // Status adicionado
    },
    // Adicione mais objetos de dados conforme necessário
  ];

  const downloadCSV = () => {
    const headers = ['Name', 'Amount', 'Payment Date', 'Payment Method', 'Status'];
    const rows = feesData.map(fees => [
      fees.name,
      fees.amount,
      fees.payment_date,
      fees.payment_method,
      fees.status
    ]);

    let csvContent = "data:text/csv;charset=utf-8," 
      + headers.join(',') + '\n'
      + rows.map(e => e.join(',')).join('\n');

    // Create a link element and trigger the download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'dados_extracurricular.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 'Aprovado':
        return 'btn-success'; // Verde
      case 'Rejeitado':
        return 'btn-danger'; // Vermelho
      default:
        return 'btn-warning'; // Amarelo
    }
  };

  return (
    <>
      <div className='page-wrapper'>
        <Header />
        <Sidebar />
        <div className="row container-fluid p-5">
          <div className="col-sm-12">
            <div className="card card-table">
              <div className="card-body">
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col">
                      <h3 className="page-title">Pagamentos</h3>
                    </div>
                    <div className="col-auto text-end float-end ms-auto download-grp">
                    <Button className="btn btn-outline-primary me-2" onClick={downloadCSV}>
                        <i className="fas fa-download"></i> Download
                      </Button>
                      <a href="/add-fees" className="btn btn-primary">
                        <i className="fas fa-plus"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                    <thead className="student-thread">
                      <tr>
                        <th>ID</th>
                        <th>Nome do Educando</th>
                        <th>Montante</th>
                        <th>Data do Pagamento</th>
                        <th>Metodo de Pagamento</th>
                        <th>Status</th> {/* Nova coluna Status */}
                        <th className="text-end">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Utilize map para renderizar dinamicamente as linhas da tabela */}
                      {feesData.map((fee) => (
                        <tr key={fee.id}>
                          <td>{fee.id}</td>
                          <td>
                            <h2>
                              {fee.name}
                            </h2>
                          </td>
                          <td>{fee.amount}</td>
                          <td>{fee.payment_date}</td>
                          <td>{fee.payment_method}</td>
                          <td>
                            <span className={`btn btn-sm ${getStatusClass(fee.status)}`}>
                              {fee.status}
                            </span>
                          </td>
                          <td className="text-end">
                            <div className="actions">
                              <a href="/" className="btn btn-sm bg-success-light me-2">
                                <i className="fa-regular fa-eye"></i>
                              </a>
                              <a href="/edit-fees" className="btn btn-sm bg-danger-light">
                                <i className="fa-regular fa-pen-to-square"></i>
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default FeesTable;
