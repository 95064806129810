import React, { useState } from 'react';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Footer from '../Components/Footer';

const EditFeesForm = () => {
  // Estados para os campos do formulário
  const [formData, setFormData] = useState({
    feesId: 'PRE1234',
    feesType: 'Exam Fees',
    gender: '9',
    feesAmount: '$152',
    startDate: '23 Apr 2020',
    endDate: '28 Apr 2020'
  });

  // Opções para o campo Gender
  const genderOptions = [
    { value: '', label: '' },
    { value: 'LKG', label: 'LKG' },
    { value: 'UKG', label: 'UKG' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' }
  ];

  // Função para lidar com a submissão do formulário
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Lógica para enviar formData para API
      console.log('Form data to be sent:', formData);

      // Limpar o formulário após a submissão bem-sucedida (opcional)
      // setFormData({
      //   feesId: '',
      //   feesType: '',
      //   gender: '',
      //   feesAmount: '',
      //   startDate: '',
      //   endDate: ''
      // });

    } catch (error) {
      console.error('Error submitting the form:', error);
      // Lógica para lidar com erros de envio
    }
  };

  // Função para atualizar o estado do formulário
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  return (
    <div className="page-wrapper">
      <Header />
      <Sidebar />
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Edit Fees</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item"><a href="fees.html">Fees</a></li>
                <li className="breadcrumb-item active">Edit Fees</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12">
                      <h5 className="form-title"><span>Fees Information</span></h5>
                    </div>

                    {/* Mapeamento dos campos do formulário */}
                    {Object.keys(formData).map((key, index) => (
                      <div className="col-12 col-sm-4" key={index}>
                        <div className="form-group local-forms">
                          <label>{key.charAt(0).toUpperCase() + key.slice(1)}</label>
                          {key === 'feesType' ? (
                            <select
                              className="form-control select"
                              name={key}
                              value={formData[key]}
                              onChange={handleInputChange}
                            >
                              <option>Exam Fees</option>
                              <option>Class Test</option>
                              <option>Hostel Fees</option>
                              <option>Transport Fees</option>
                              <option>Mess Fees</option>
                            </select>
                          ) : key === 'gender' ? (
                            <select
                              className="form-control select"
                              name={key}
                              value={formData[key]}
                              onChange={handleInputChange}
                            >
                              {genderOptions.map(option => (
                                <option key={option.value} value={option.value}>{option.label}</option>
                              ))}
                            </select>
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              name={key}
                              value={formData[key]}
                              onChange={handleInputChange}
                            />
                          )}
                        </div>
                      </div>
                    ))}

                    <div className="col-12">
                      <div className="student-submit">
                        <button type="submit" className="btn btn-primary">Submit</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default EditFeesForm;
