import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { Link } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import { Modal, Button, Form } from "react-bootstrap";
import Footer from "../Components/Footer";
import env from "../utils/env";
import ToastAlert from "../utils/ToastAlert";
import avatar from "../assets/img/profiles/avatar-01.jpg";
import icons96 from "../assets/img/icons8-user-96.png";

const Employees = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [employee, setEmployee] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    gender: "",
    address: "",
    data_of_birth: "",
    full_picture_url: "",
    status: "",
    other_positions: "",
    position: "",
    check_in_time: "",
    check_out_time: "",
    contract_type: "",
    termination_date: "",
    district_id: "",
    hire_date: "",
    contracts: [], // Inicializando com um array vazio
  });

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await fetch(
          `${env.base_url}schools/${user.school.id}/employees`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        const data = await response.json();
        console.log(data.data);
        setEmployees(data.data);
      } catch (error) {
        console.error("Erro ao carregar os dados dos funcionários:", error);
      }
    };

    fetchEmployees();
  }, []);

  useEffect(() => {
    if (selectedEmployee) {
      setEmployee({
        first_name: selectedEmployee.first_name || "",
        last_name: selectedEmployee.last_name || "",
        email: selectedEmployee.email || "",
        phone: selectedEmployee.phone || "",
        gender: selectedEmployee.gender || "",
        address: selectedEmployee.address || "",
        data_of_birth: selectedEmployee.data_of_birth || "",
        full_picture_url: selectedEmployee.full_picture_url || "",
        status: selectedEmployee.status || "",
        other_positions: selectedEmployee.other_positions || "",
        position: selectedEmployee.position || "",
        check_in_time: selectedEmployee.check_in_time || "",
        check_out_time: selectedEmployee.check_out_time || "",
        contract_type: selectedEmployee.contract_type || "",
        termination_date: selectedEmployee.termination_date || "",
        district_id: selectedEmployee.district_id || "",
        hire_date: selectedEmployee.hire_date || "",
        contracts: selectedEmployee.contracts || [],
      });
    }
  }, [selectedEmployee]);

  const handleEdit = (employee) => {
    setSelectedEmployee(employee);
    setShowModal(true);
  };

  const handleUpdate = async (schoolId) => {
    try {
      const response = await fetch(
        `${env.base_url}schools/053b8ab8-c43c-40bc-a3ef-8dd4bba2f906/employees/${selectedEmployee.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(employee),
        }
      );

      const { message, errors } = await response.json();
      if (response.ok) {
        setShowModal(false);
        setLoading(false);


        ToastAlert({ icon: "success", title: "Success", text: message });
      } else {
        ToastAlert({ icon: "error", title: "Error", text: errors });
      }
    } catch (error) {
      console.error("Erro ao salvar os dados do funcionário:", error);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setEmployee((prevData) => ({
          ...prevData,
          avatar: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEmployee((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, "0")}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
  };

  return (
    <div className="page-wrapper">
      <Header />
      <Sidebar />
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-sub-header">
                <h3 className="page-title">Funcionários</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="staff.html">Funcionários</a>
                  </li>
                  <li className="breadcrumb-item active">Todos Funcionários</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="employee-group-form">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Buscar por ID ..."
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Buscar por Nome ..."
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Buscar por Telefone ..."
                />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="search-employee-btn">
                <button type="btn" className="btn btn-primary">
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table comman-shadow">
              <div className="card-body">
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col">
                      <h3 className="page-title">Funcionários</h3>
                    </div>
                    <div className="col-auto text-end float-end ms-auto download-grp">
                      <a
                        href="staff.html#"
                        className="btn btn-outline-primary me-2"
                      >
                        <i className="fas fa-download"></i> Download
                      </a>
                      <a href="/add-employee" className="btn btn-primary">
                        <i className="fas fa-plus"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table border-0 star-employee table-hover table-center mb-0 datatable table-striped">
                    <thead className="employee-thread">
                      <tr>
                        <th>
                          <div className="form-check check-tables">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value="something"
                            />
                          </div>
                        </th>
                        <th>#</th>
                        <th>Nome</th>
                        <th>Cargo</th>
                        <th>Email</th>
                        <th>Contratado Em</th>
                        <th>Contato</th>
                        <th>Endereço</th>
                        <th>Status</th>
                        <th className="text-end">Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      {employees &&
                        employees.map((employee, index) => (
                          <tr key={index}>
                            <td>
                              <div className="form-check check-tables">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value="something"
                                />
                              </div>
                            </td>
                            <td>{index + 1}</td>
                            <td>
                              <h2 className="table-avatar">
                                <a
                                  href="employee-details.html"
                                  className="avatar avatar-sm me-2"
                                >
                                  <img
                                    className="avatar-img rounded-circle"
                                    src={employee.full_picture_url || avatar} // Corrigido para full_picture_url
                                    alt="UserImage"
                                  />
                                </a>
                                <a href="employee-details.html">
                                  {employee.first_name} {employee.last_name}
                                </a>
                              </h2>
                            </td>
                            <td>
                              {employee.contracts.length > 0
                                ? employee.contracts[0].position
                                : "N/A"}
                            </td>
                            <td>{employee.email || "N/A"}</td>
                            <td>
                              {employee.contracts.length > 0
                                ? formatDate(employee.contracts[0].hire_date)
                                : "N/A"}
                            </td>
                            <td>{employee.phone}</td>
                            <td>{employee.address}</td>
                            <td>
                              {employee.contracts.length > 0
                                ? employee.contracts[0].status
                                : "N/A"}
                            </td>
                            <td className="text-end">
                              <a
                                href={`/employee-details/${employee.id}`}
                                className="btn btn-sm bg-success-light me-2"
                                style={{ borderRadius: "5px" }}
                              >
                                <i className="fa-regular fa-eye"></i>
                              </a>
                              <button
                                onClick={() => handleEdit(employee)}
                                className="btn btn-sm bg-warning-light me-2"
                              >
                                <i className="fa-solid fa-pencil"></i>
                              </button>
                              <button
                                className="btn btn-sm bg-danger"
                                style={{ borderRadius: "5px" }}
                              >
                                <i className="fa-solid fa-trash text-white"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <Modal centered size="lg" show={showModal} onHide={setShowModal}>
          <Modal.Header closeButton>
            <Modal.Title>Editar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <div
                  className="profile-picture"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <img
                    src={employee.full_picture_url || icons96} // Placeholder image
                    alt="Avatar Preview"
                    className="avatar"
                    style={{
                      borderRadius: "50%",
                      width: "100px",
                      height: "100px",
                    }}
                  />
                  <label
                    htmlFor="upload-photo"
                    className="camera-icon"
                    style={{
                      position: "relative",
                      top: "70px",
                      right: "30px",
                      cursor: "pointer",
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "red",
                    }}
                  >
                    <i
                      className="fas fa-camera"
                      style={{ fontSize: "15px", color: "white" }}
                    ></i>
                  </label>
                  <input
                    type="file"
                    id="upload-photo"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type="text"
                  name="first_name"
                  value={employee.first_name}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Apelido</Form.Label>
                <Form.Control
                  type="text"
                  name="last_name"
                  value={employee.last_name}
                  onChange={handleChange}
                />
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Contato</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={employee.phone}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Endereço</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  value={employee.address}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Cargo</Form.Label>
                <Form.Control
                  type="text"
                  name="position"
                  value={employee.contracts[0]?.position}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Control
                  as="select"
                  name="status"
                  className="form-control"
                  onChange={handleChange}
                  value={employee.contracts[0]?.status}
                >
                  <option value="">Selecione um Status</option>
                  <option value="Activo">Activo</option>
                  <option value="Inativo">Inativo</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleUpdate}>
              {loading ? "Salvando..." : "Salvar"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Employees;
