import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Step, StepLabel, Stepper } from "@mui/material";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useAuth } from "../Context/AuthContext";
import env from "../utils/env";
import ToastAlert from "../utils/ToastAlert";

const AddPlanModal = ({ isOpen, onRequestClose }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [developmentAreas, setDevelopmentAreas] = useState([""]);
  const [generalObjectives, setGeneralObjectives] = useState("");
  const [specificObjectives, setSpecificObjectives] = useState([""]);
  const [developmentActivities, setDevelopmentActivities] = useState([""]);
  const [activityType, setActivityType] = useState("");
  const [activityDescrption, setActivityDescription] = useState("");
  const [schedule, setSchedule] = useState("");
  const [planName, setPlanName] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [teacherId, setTeacherId] = useState(""); // Estado para teacher_id
  const [schoolLevelId, setSchoolLevelId] = useState(""); // Estado para school_level_id
  const [teachers, setTeachers] = useState([]);
  const [levels, setLevels] = useState([]);
  const { user } = useAuth();

  const steps = ["Componente curricular", "Plano de ensino", "Atividades"];

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const response = await fetch(
          `${env.base_url}schools/${user.school.id}/teachers`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        const { data } = await response.json();

        setTeachers(data);
        setTeacherId(data[0]?.id || "");
      } catch (error) {
        console.error("Erro ao buscar os dados dos professores:", error);
      }
    };
    const levels = async () => {
      try {
        const response = await fetch(
          `${env.base_url}schools/${user.school.id}/levels`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        const { data } = await response.json();

        setLevels(data);
        setSchoolLevelId(data[0]?.id || "");
      } catch (error) {
        console.error("Erro ao buscar os dados dos professores:", error);
      }
    };

    levels();
    fetchTeachers();
  }, [user]);

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async () => {
    try {
      // *** Etapa 1: Criar Componente Curricular ***
      const payload = {
        name: name,
        description: description,
        development_area: developmentAreas,
        teacher_id: teacherId,
        school_level_id: schoolLevelId,
      };

      const response = await fetch(
        `${env.base_url}schools/${user.school.id}/curricular-components`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      let componentId; // Variável para armazenar o ID do componente

      if (response.ok) {
        const componentData = await response.json(); // Lê a resposta apenas uma vez
        componentId = componentData.data.id;

        ToastAlert({
          icon: "success",
          title: "Sucesso!",
          text: componentData.message, // Exibe a mensagem de sucesso
        });
      } else {
        const errorData = await response.json(); // Lê a resposta em caso de erro
        ToastAlert({
          icon: "error",
          title: "Erro!",
          text: errorData.errors,
        });

        return; // Interrompe o processo se houver erro
      }

      // *** Etapa 2: Criar Plano de Ensino ***
      const planPayload = {
        name: planName,
        general_objectives: generalObjectives,
        specific_objectives: specificObjectives,
        schedule: schedule,
        curricular_component_id: componentId,
      };
      console.log(planPayload);

      const planResponse = await fetch(
        `${env.base_url}schools/${user.school.id}/teaching-plans`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(planPayload),
        }
      );

      let teachingPlanId;

      if (planResponse.ok) {
        const planData = await planResponse.json();
        teachingPlanId = planData.data.id;

        ToastAlert({
          icon: "success",
          title: "Sucesso!",
          text: planData.message,
        });
      } else {
        const errorData = await planResponse.json();
        ToastAlert({
          icon: "error",
          title: "Erro!",
          text: errorData.errors,
        });

        return;
      }

      // *** Etapa 3: Criar Atividade ***
      const activityLoad = {
        type: activityType,
        description: activityDescrption,
        development_area: developmentActivities,
        teaching_plan_id: teachingPlanId,
      };
      console.log(activityLoad);

      const activityResponse = await fetch(
        `${env.base_url}schools/${user.school.id}/activities`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(activityLoad),
        }
      );

      if (activityResponse.ok) {
        const activityData = await activityResponse.json();
        ToastAlert({
          icon: "success",
          title: "Sucesso!",
          text: activityData.message,
        });
        onRequestClose();
      } else {
        const errorData = await activityResponse.json();
        ToastAlert({
          icon: "error",
          title: "Erro!",
          text: errorData.errors,
        });
      }
    } catch (error) {
      console.error("Erro ao enviar os dados:", error);
      ToastAlert({
        icon: "error",
        title: "Erro!",
        text: "Ocorreu um erro ao processar sua solicitação.",
      });
    }
  };

  const handleAddArea = () => {
    setDevelopmentAreas([...developmentAreas, ""]);
  };

  const handleRemoveArea = (index) => {
    setDevelopmentAreas(developmentAreas.filter((_, i) => i !== index));
  };

  const handleAreaChange = (index, value) => {
    const newAreas = [...developmentAreas];
    newAreas[index] = value;
    setDevelopmentAreas(newAreas);
  };

  const handleAddObjective = () => {
    setSpecificObjectives([...specificObjectives, ""]);
  };

  const handleRemoveObjective = (index) => {
    setSpecificObjectives(specificObjectives.filter((_, i) => i !== index));
  };

  const handleObjectiveChange = (index, value) => {
    const newObjectives = [...specificObjectives];
    newObjectives[index] = value;
    setSpecificObjectives(newObjectives);
  };
  const handleAddActivitie = () => {
    setDevelopmentActivities([...developmentActivities, ""]);
  };

  const handleRemoveActivitie = (index) => {
    setDevelopmentActivities(
      developmentActivities.filter((_, i) => i !== index)
    );
  };

  const handleActivitieChange = (index, value) => {
    const newObjectives = [...developmentActivities];
    newObjectives[index] = value;
    setDevelopmentActivities(newObjectives);
  };

  return (
    <Modal size="lg" centered show={isOpen} onHide={onRequestClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h2>Adicionar Novo Plano Curricular</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Stepper activeStep={currentStep}>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {/* Renderiza o conteúdo do step atual */}
        <div>
          {currentStep === 0 && (
            <Form>
              <Form.Group controlId="formName">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Nome do Componente Curricular"
                />
              </Form.Group>

              <Form.Group controlId="formDescription">
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Descrição do Componente Curricular"
                />
              </Form.Group>

              <Form.Group controlId="formDevelopmentAreas">
                <Form.Label>Áreas de Desenvolvimento</Form.Label>
                {developmentAreas.map((area, index) => (
                  <div key={index} className="d-flex mb-2">
                    <Form.Control
                      type="text"
                      placeholder="Área de Desenvolvimento"
                      value={area}
                      onChange={(e) => handleAreaChange(index, e.target.value)}
                    />
                    <Button
                      variant="outline-success"
                      onClick={handleAddArea}
                      className="ms-2"
                    >
                      +
                    </Button>
                    {developmentAreas.length > 1 && (
                      <Button
                        variant="outline-danger"
                        onClick={() => handleRemoveArea(index)}
                        className="ms-2"
                      >
                        -
                      </Button>
                    )}
                  </div>
                ))}
              </Form.Group>

              <Form.Group controlId="formTeacher">
                <Form.Label>Professor</Form.Label>
                <Form.Control
                  as="select"
                  value={teacherId}
                  onChange={(e) => setTeacherId(e.target.value)}
                >
                  {teachers.map((teacher) => (
                    <option key={teacher.id} value={teacher.id}>
                      {teacher.full_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="formSchoolLevel">
                <Form.Label>Nível Escolar</Form.Label>
                <Form.Control
                  as="select"
                  value={schoolLevelId}
                  onChange={(e) => setSchoolLevelId(e.target.value)}
                >
                  {levels.map((level) => (
                    <option key={level.id} value={level.id}>
                      {level.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form>
          )}
          {currentStep === 1 && (
            <Form>
              <Form.Group controlId="formPlanName">
                <Form.Label>Nome do Plano</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nome do Plano"
                  value={planName}
                  onChange={(e) => setPlanName(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formGeneralObjectives">
                <Form.Label>Objetivos Gerais</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Objetivos Gerais"
                  value={generalObjectives}
                  onChange={(e) => setGeneralObjectives(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formSpecificObjectives">
                <Form.Label>Objetivos Específicos</Form.Label>
                {specificObjectives.map((objective, index) => (
                  <div key={index} className="d-flex mb-2">
                    <Form.Control
                      type="text"
                      placeholder="Objetivo Específico"
                      value={objective}
                      onChange={(e) =>
                        handleObjectiveChange(index, e.target.value)
                      }
                    />
                    <Button
                      variant="outline-success"
                      onClick={handleAddObjective}
                      className="ms-2"
                    >
                      +
                    </Button>
                    {specificObjectives.length > 1 && (
                      <Button
                        variant="outline-danger"
                        onClick={() => handleRemoveObjective(index)}
                        className="ms-2"
                      >
                        -
                      </Button>
                    )}
                  </div>
                ))}
              </Form.Group>

              <Form.Group controlId="formSchedule">
                <Form.Label>Horário</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Horário do Plano de Ensino"
                  value={schedule}
                  onChange={(e) => setSchedule(e.target.value)}
                />
              </Form.Group>
            </Form>
          )}
          {currentStep === 2 && (
            <Form>
              <Form.Group controlId="formPlanName">
                <Form.Label>Tipo de atividade</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Tipo de atividade"
                  value={activityType}
                  onChange={(e) => setActivityType(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formGeneralObjectives">
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Descrição"
                  value={activityDescrption}
                  onChange={(e) => setActivityDescription(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formSpecificObjectives">
                <Form.Label>Objetivos Específicos</Form.Label>
                {developmentActivities.map((Activities, index) => (
                  <div key={index} className="d-flex mb-2">
                    <Form.Control
                      type="text"
                      placeholder="Objetivo Específico"
                      value={Activities}
                      onChange={(e) =>
                        handleActivitieChange(index, e.target.value)
                      }
                    />
                    <Button
                      variant="outline-success"
                      onClick={handleAddActivitie}
                      className="ms-2"
                    >
                      +
                    </Button>
                    {developmentActivities.length > 1 && (
                      <Button
                        variant="outline-danger"
                        onClick={() => handleRemoveActivitie(index)}
                        className="ms-2"
                      >
                        -
                      </Button>
                    )}
                  </div>
                ))}
              </Form.Group>
            </Form>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button
            onClick={handleBack}
            disabled={currentStep === 0}
            style={{ marginRight: "10px" }}
          >
            Voltar
          </Button>
          {currentStep === steps.length - 1 ? (
            <Button variant="danger" onClick={handleSubmit}>
              Enviar
            </Button>
          ) : (
            <Button variant="danger" onClick={handleNext}>
              Próximo
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddPlanModal;
