import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Footer from '../Components/Footer';
import env from '../utils/env';
import { useAuth } from "../Context/AuthContext";
import ToastAlert from "../utils/ToastAlert";
const EditClass = () => {
  const { classId } = useParams(); // Obtém o ID da turma dos parâmetros da URL
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [className, setClassName] = useState('');
  const [year, setYear] = useState('');
  const [description, setDescription] = useState('');
  const [level, setLevel] = useState('');
  const [teacher, setTeacher] = useState('');

  useEffect(() => {
    const fetchClass = async () => {
      try {
        const response = await fetch(`${env.base_url}schools/${user.school.id}/classes/${classId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (response.ok) {
          const {data} = await response.json();
          console.log(data); // Verificar dados retornados
          setClassName(data.name || '');
          setYear(data.year || '');
          setDescription(data.description || '');
          setLevel(data.level?.name || '');
          setTeacher(data.teacher?.first_name + ' ' + data.teacher?.last_name || '');
        } else {
          console.error('Failed to fetch class data');
        }
      } catch (error) {
        console.error('Error fetching class data:', error);
      }
    };

    fetchClass();
  }, [classId, user.school.id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedClass = {
      name: className,
      year: year,
      description: description,
      level: level,
      teacher: teacher,
    };

    setLoading(true);

    try {
      const response = await fetch(`${env.base_url}schools/${user.school.id}/classes/${classId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(updatedClass),
      });

      const { message, errors } = await response.json();
      if (response.ok) {
        ToastAlert({ icon: "success", title: "Success", text: message });
        setLoading(false);
      } else {
        ToastAlert({ icon: "error", title: "Error", text: errors });
      }
    
    } catch (error) {
      console.error('Error updating class:', error);
    }
  };

  return (
    <div className="page-wrapper">
      <Header />
      <Sidebar />
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Editar Turma</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item"><a href="/classes">Turmas</a></li>
                <li className="breadcrumb-item active">Editar Turma</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12">
                      <h5 className="form-title"><span>Informações da Turma</span></h5>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <label>Nome da Turma <span className="login-danger">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          value={className}
                          onChange={(e) => setClassName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    {/* <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <label>Ano <span className="login-danger">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          value={year}
                          onChange={(e) => setYear(e.target.value)}
                          required
                        />
                      </div>
                    </div> */}
                    {/* <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <label>Professor</label>
                        <input
                          type="text"
                          className="form-control"
                          value={teacher}
                          onChange={(e) => setTeacher(e.target.value)}
                        />
                      </div>
                    </div> */}
                    
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <label>Nível</label>
                        <input
                          type="text"
                          className="form-control"
                          value={level}
                          onChange={(e) => setLevel(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-12 ">
                      <div className="form-group">
                        <label>Descrição</label>
                        <textarea
                          rows={5}
                          type="text"
                          className="form-control"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="student-submit">
                        <button type="submit" className="btn btn-primary">
                          {loading ? 'Carregando...' : 'Atualizar'}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default EditClass;
