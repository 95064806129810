import React, { useState, useEffect } from "react";
import { useAuth } from "../Context/AuthContext";
import env from "../utils/env";
import ToastAlert from "../utils/ToastAlert";

const SchoolDetailsCard = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    full_logo_url: "",
    full_cover_url: "",
    phone: "",
    address: "",
    city: "",
    description: "",
    province: { name: "", id: "" },
    district: { name: "", id: "" },
    info_email: "",
    support_email: "",
    open_time: "",
    close_time: "",
    capacity: "",
    classrooms: "",
    duration: "",
    education_type: "",
    enrollment_fee: "",
    enrollment_contact: "",
    user_id: user.id,
    pictures: [],
    id: "",
    logo: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Função para formatar a hora no formato H:i:s
  const formatTime = (time) => {
    const [hours, minutes] = time.split(":");
    return `${hours}:${minutes}:00`; // Adiciona os segundos como 00
  };

  const fetchSchoolDetails = async () => {
    try {
      const response = await fetch(
        `${env.base_url}schools/${user.school.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Erro: ${response.status}`);
      }

      const { data } = await response.json();

      setFormData({
        name: data.name || "",
        full_logo_url: data.full_logo_url || "",
        full_cover_url: data.full_cover_url || "",
        address: data.address || "",
        city: data.city || "",
        provinces: data.provinces || "",
        phone: data.phone || "",
        province: data.province || { name: "", id: "" },
        district: data.district || { name: "", id: "" },
        country: data.country || "",
        open_time: data.open_time || "",
        close_time: data.close_time || "",
        operating_hours: data.operating_hours || "",
        age_range: data.age_range || "",
        capacity: data.capacity || "",
        description: data.description || "",
        info_email: data.info_email || "",
        support_email: data.support_email || "",
        classrooms: data.classrooms || "",
        education_type: data.education_type || "",
        duration: data.duration || "",
        districts: data.districts || "",
        photos: data.pictures || [],
        enrollment_fee: data.enrollment_fee || "",
        enrollment_open: data.enrollment_open || "",
        enrollment_contact: data.enrollment_contact || "",
        user_id: user.id,
        email: data.email || "",
        id: data.id || "",
        logo: "",
      });
    } catch (error) {
      console.error("Erro ao buscar dados do endereço:", error);
    }
  };

  const updateDetails = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formattedData = {
        ...formData,
        open_time: formatTime(formData.open_time),
        close_time: formatTime(formData.close_time),
      };

      const response = await fetch(`${env.base_url}schools/${formData.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(formattedData),
      });

      const { message, errors } = await response.json();

      if (response.ok) {
        ToastAlert({ icon: "success", title: "Success", text: message });
      } else {
        ToastAlert({ icon: "error", title: "Error", text: errors });
      }
    } catch (error) {
      console.error("Erro ao enviar os dados:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSchoolDetails();
  }, []);

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title">Detalhes da Escola</h5>
      </div>
      <div className="card-body pt-0">
        <form onSubmit={updateDetails}>
          <div className="settings-form">
            <div className="form-group">
              <div className="row">
                <div className="col-md-6 form-group">
                  <label>Inscrição</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Inscrição"
                    name="enrollment_fee"
                    value={formData.enrollment_fee}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label>Capacidade</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Insira a capacidade"
                    name="capacity"
                    value={formData.capacity}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label>Abertura</label>
                <input
                  type="time"
                  className="form-control"
                  placeholder="Insira o horário de abertura"
                  name="open_time"
                  value={formData.open_time}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <label>Fecho</label>
                <input
                  type="time"
                  className="form-control"
                  placeholder="Insira o horário de fecho"
                  name="close_time"
                  value={formData.close_time}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-group mt-3">
              <div className="settings-btns">
                <button
                  type="submit"
                  className="btn btn-danger"
                  disabled={loading}
                  style={{ marginRight: "10px" }}
                >
                  {loading ? "Atualizando..." : "Atualizar"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SchoolDetailsCard;
