import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import "../assets/css/sidebar.css";
import AddPlanModal from "./AddPlanModal";
const Sidebar = () => {
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [paymentOpen, setPaymentOpen] = useState(false);

  
  const [modalOpen, setModalOpen] = useState(false); 
  const location = useLocation();

  const toggleSettings = () => {
    setSettingsOpen(!settingsOpen);
  };

  const togglePayment = () => {
    setPaymentOpen(!paymentOpen);
  };


  const handleCloseModal = () => {
    setModalOpen(false);
  };
  

  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <ul>
            <li className="menu-title">
              <span>Main Menu</span>
            </li>
            <li className="submenu">
              <NavLink to="/" exact activeClassName="active">
                <i className="fa fa-columns"></i> <span>Pagina Inicial</span>
                <span className="menu-arrow"></span>
              </NavLink>
            </li>

            <li className="submenu">
              <NavLink to="/pre-registrations" activeClassName="active">
                <i className="fa-regular fa-address-card"></i>
                <span>Pre-Inscrições</span>
                <span className="menu-arrow"></span>
              </NavLink>
            </li>

            <li className="submenu">
              <NavLink to="/enrolment" activeClassName="active">
                <i className="fa-regular fa-address-card"></i>
                <span>Inscrições</span>
                <span className="menu-arrow"></span>
              </NavLink>
            </li>

            <li className="submenu">
              <NavLink to="/student" activeClassName="active">
                <i className="fa-solid fa-graduation-cap"></i>
                <span>Educandos</span>
                <span className="menu-arrow"></span>
              </NavLink>
            </li>
            <li className="submenu">
              <NavLink to="/teachers" activeClassName="active">
                <i className="fa-solid fa-chalkboard-user"></i>
                <span>Professores</span>
                <span className="menu-arrow"></span>
              </NavLink>
            </li>
            <li className="submenu">
              <NavLink to="/class" activeClassName="active">
                <i className="fas fa-users me-2"></i>
                <span>Turma</span>
                <span className="menu-arrow"></span>
              </NavLink>
            </li>
            <li className="submenu">
              <NavLink to="/grade-curricular" activeClassName="active">
                <i className="fas fa-users me-2"></i>
                <span>Plano curricular</span>
                <span className="menu-arrow"></span>
              </NavLink>
            </li>

            <li className="submenu">
              <NavLink to="/gallery" activeClassName="active">
                <i className="fas fa-image"></i> <span>Galeria</span>
                <span className="menu-arrow"></span>
              </NavLink>
            </li>
            <li
              className={`submenu ${
                paymentOpen || location.pathname.includes("/payments")
                  ? "open"
                  : ""
              }`}
            >
              <a href="#payments" onClick={togglePayment}>
                <i className="fas fa-credit-card"></i>{" "}
                <span>Gestão de Pagamentos</span>
                <span className="menu-arrow"></span>
              </a>
              <ul
                className={`settings-submenu ${
                  paymentOpen || location.pathname.includes("/payments")
                    ? "show"
                    : ""
                }`}
              >
                <li>
                  <NavLink to="/payments/invoices" activeClassName="active">
                    <i className="fas fa-file-invoice me-2"></i>{" "}
                    <span>Faturas</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/payments/transactions" activeClassName="active">
                    <i className="fas fa-exchange-alt me-2"></i>{" "}
                    <span>Transações</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/payments/reports" activeClassName="active">
                    <i className="fas fa-chart-line me-2"></i>{" "}
                    <span>Relatórios</span>
                  </NavLink>
                </li>
              </ul>
            </li>

            <li
              className={`submenu ${
                settingsOpen || location.pathname.includes("/settings")
                  ? "open"
                  : ""
              }`}
            >
              <a href="#settings" onClick={toggleSettings}>
                <i className="fas fa-cog"></i> <span>Configurações</span>
                <span className="menu-arrow"></span>
              </a>
              <ul
                className={`settings-submenu ${
                  settingsOpen || location.pathname.includes("/settings")
                    ? "show"
                    : ""
                }`}
              >
                <li>
                  <NavLink
                    to="/settings/centro-infantil"
                    activeClassName="active"
                  >
                    <i className="fas fa-school me-2"></i>{" "}
                    <span>Centro Infantil</span>
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink to="/settings/subjects" activeClassName="active">
                    <i className="fas fa-book-reader"></i>{" "}
                    <span>Disciplinas</span>
                  </NavLink>
                </li> */}
                <li>
                  <NavLink
                    to="/settings/extra-lessons"
                    activeClassName="active"
                  >
                    <i className="fa-solid fa-chess me-2"></i>
                    <span>Extracurriculares</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/settings/employees" activeClassName="active">
                    <i className="fa-solid fa-users me-2"></i>
                    <span>Colaboradores</span>
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <AddPlanModal isOpen={modalOpen} onRequestClose={handleCloseModal} />
    </div>
  );
};

export default Sidebar;
