import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import Footer from "../Components/Footer";
import { Button, Modal, Form } from "react-bootstrap";
import env from "../utils/env";
import ToastAlert from "../utils/ToastAlert";
import { useAuth } from "../Context/AuthContext";

const PlanoEnsino = () => {
  const { user } = useAuth();
  const [activities, setActivities] = useState([]);
  const [classes, setClasses] = useState([]);
  const [periods, setPeriods] = useState({});
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState({
    id: "", // Adicione o id aqui
    period: "",
    start_time: "",
    end_time: "",
  });

  const [step, setStep] = useState(1); // Estado para gerenciar os passos
  const [newActivityDescription, setNewActivityDescription] = useState(""); // Descrição da nova atividade
  const [newPeriod, setNewPeriod] = useState({
    period: "",
    start_time: "",
    end_time: "",
  }); // Estado para o período

  const [newActivityId, setNewActivityId] = useState(null); // Para armazenar o ID da nova atividade

  // Modal para editar descrição da atividade
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [editDescription, setEditDescription] = useState("");

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await fetch(
          `${env.base_url}schools/${user.school.id}/activities`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        const { data } = await response.json();
        console.log(data);
        setActivities(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchClasses = async () => {
      try {
        const response = await fetch(
          `${env.base_url}schools/${user.school.id}/classes`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        const { data } = await response.json();
        setClasses(data);
      } catch (error) {
        console.error("Erro ao buscar os dados das turmas:", error);
      }
    };

    fetchActivities();
    fetchClasses();
  }, [user]);

  const fetchPeriods = async (classId, activityId) => {
    try {
      const response = await fetch(
        `${env.base_url}classes/${classId}/activities/${activityId}/periods`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      const { data } = await response.json();
      console.log(data);
      setPeriods(data);

      if (data.length > 0) {
        const firstPeriod = data[0];
        setSelectedPeriod({
          id: firstPeriod.id, // Adicione o id aqui
          period: firstPeriod.period,
          start_time: firstPeriod.start_time,
          end_time: firstPeriod.end_time,
        });
        setSelectedClass(firstPeriod.class_id);
      }
    } catch (error) {
      console.error("Erro ao buscar os dados dos períodos:", error);
    }
  };

  const handleShowModal = (activity) => {
    setSelectedActivity(activity);
    setShowModal(true);
    fetchPeriods(activity.class_id, activity.id);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleClassChange = (event) => {
    const classId = event.target.value;
    setSelectedClass(classId);

    if (selectedActivity) {
      fetchPeriods(classId, selectedActivity.id);
    }
  };

  // Abrir modal de edição
  const handleShowEditModal = (activity) => {
    setSelectedActivity(activity);
    setEditDescription(activity.description);
    setShowEditModal(true);
  };

  // Fechar modal de edição
  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  // Enviar a descrição atualizada para a API
  const handleEditSubmit = async () => {
    try {
      const response = await fetch(
        `${env.base_url}schools/${user.school.id}/activities/${selectedActivity.id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            description: editDescription,
          }),
        }
      );
      const { message, errors } = await response.json();

      if (response.ok) {
        ToastAlert({ icon: "success", title: "success", text: message });
        // Atualizar a lista de atividades com a nova descrição
        setActivities((prevActivities) =>
          prevActivities.map((activity) =>
            activity.id === selectedActivity.id
              ? { ...activity, description: editDescription }
              : activity
          )
        );
        handleCloseEditModal();
      } else {
        ToastAlert({ icon: "error", title: "success", text: errors });
      }
    } catch (error) {
      console.error("Erro ao fazer a requisição:", error);
    }
  };
  const handleDeleteSubmit = async () => {
    try {
      const response = await fetch(
        `${env.base_url}schools/${user.school.id}/activities/${selectedActivity.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        }
      );
      const { message, errors } = await response.json();

      if (response.ok) {
        ToastAlert({ icon: "success", title: "success", text: message });
        // Atualizar a lista de atividades com a nova descrição
        setActivities((prevActivities) =>
          prevActivities.map((activity) =>
            activity.id === selectedActivity.id
              ? { ...activity, description: editDescription }
              : activity
          )
        );
        handleCloseEditModal();
      } else {
        ToastAlert({ icon: "error", title: "success", text: errors });
      }
    } catch (error) {
      console.error("Erro ao fazer a requisição:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
   
    const updatedPeriodData = {
      class_id: selectedClass, // ID da classe selecionada
      period: selectedPeriod.period,
      start_time: selectedPeriod.start_time,
      end_time: selectedPeriod.end_time,
    };
  
    try {
      const response = await fetch(
        `${env.base_url}classes/${selectedClass}/activities/${selectedActivity.id}/periods/${selectedPeriod.id}`, // Usando o ID da classe
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(updatedPeriodData),
        }
      );
  
      const { message, errors } = await response.json();
  
      if (response.ok) {
        ToastAlert({
          icon: "success",
          title: "Período atualizado",
          text: message,
        });
        handleCloseModal(); // Fecha o modal após o sucesso
      } else {
        ToastAlert({ icon: "error", title: "Erro", text: errors });
      }
    } catch (error) {
      console.error("Erro ao atualizar o período:", error);
    }
  };
  

  const handleAddActivity = async () => {
    try {
      const response = await fetch(
        `${env.base_url}schools/${user.school.id}/activities`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            description: newActivityDescription,
          }),
        }
      );

      const { data, message, errors } = await response.json();

      if (response.ok) {
        ToastAlert({
          icon: "success",
          title: "Atividade adicionada",
          text: message,
        });
        setNewActivityId(data.id); // Armazenar o ID da nova atividade
        setStep(2); // Ir para o segundo passo
      } else {
        ToastAlert({ icon: "error", title: "Erro", text: errors });
      }
    } catch (error) {
      console.error("Erro ao adicionar atividade:", error);
    }
  };

  const handleAddPeriod = async () => {
    if (!selectedClass || !newActivityId) {
      // Verificar se os IDs estão definidos corretamente
      ToastAlert({
        icon: "error",
        title: "Erro",
        text: "Selecione uma classe e uma atividade.",
      });
      return;
    }

    try {
      const response = await fetch(
        `${env.base_url}classes/${selectedClass}/activities/${newActivityId}/periods`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newPeriod),
        }
      );

      const { message, errors } = await response.json();

      if (response.ok) {
        ToastAlert({
          icon: "success",
          title: "Período adicionado",
          text: message,
        });
        setShowAddModal(false); // Fechar modal após adicionar o período
        setStep(1); // Resetar o step para o primeiro passo
        setNewActivityDescription(""); // Resetar campos
        setNewPeriod({ period: "", start_time: "", end_time: "" });
      } else {
        ToastAlert({ icon: "error", title: "Erro", text: errors });
      }
    } catch (error) {
      console.error("Erro ao adicionar período:", error);
    }
  };

  return (
    <div className="page-wrapper">
      <Header />
      <Sidebar />
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Plano de Ensino</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="index.html">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">Plano de Ensino</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table">
              <div className="card-body">
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col-auto text-end float-end ms-auto download-grp">
                      <button className="btn btn-outline-primary me-2">
                        <i className="fas fa-download"></i> Download
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={() => setShowAddModal(true)}
                      >
                        <i className="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                    <thead className="student-thread">
                      <tr>
                        <th>#</th>
                        <th>Descrição</th>
                        <th className="text-end">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {activities.map((activity, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{activity.description}</td>
                          <td className="text-end">
                            <div className="actions">
                              <Button
                                className="btn btn-sm btn-success me-1"
                                title="Ver Detalhes"
                                onClick={() => handleShowModal(activity)}
                              >
                                <i className="fas fa-eye"></i>
                              </Button>
                              <Button
                                className="btn btn-sm btn-warning me-1"
                                title="Editar"
                                onClick={() => handleShowEditModal(activity)}
                              >
                                <i
                                  className="fas fa-edit"
                                  style={{ color: "white" }}
                                ></i>
                              </Button>
                              <Button
                                className="btn btn-sm btn-danger"
                                title="Deletar"
                                onClick={() => handleDeleteSubmit(activity)}
                              >
                                <i className="fas fa-trash"></i>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal para detalhes do período */}
        <Modal centered size="lg" show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Detalhes do Período</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="classSelect">
                <Form.Label>Selecione a Classe</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedClass}
                  onChange={handleClassChange}
                >
                  <option value="">Selecione uma classe</option>
                  {classes.map((classe) => (
                    <option key={classe.id} value={classe.id}>
                      {classe.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="period">
                <Form.Label className="mt-3">Período</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedPeriod.period}
                  onChange={(e) =>
                    setSelectedPeriod((prev) => ({
                      ...prev,
                      period: e.target.value,
                    }))
                  }
                >
                  <option value="">Selecione o período</option>
                  <option value="Manhã">Manhã</option>
                  <option value="Meio-dia">Meio-dia</option>
                  <option value="Tarde">Tarde</option>
                  <option value="Noite">Noite</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="startTimeInput">
                <Form.Label>Hora de Início</Form.Label>
                <Form.Control
                  type="time"
                  value={selectedPeriod.start_time}
                  onChange={(e) =>
                    setSelectedPeriod((prev) => ({
                      ...prev,
                      start_time: e.target.value,
                    }))
                  }
                />
              </Form.Group>

              <Form.Group controlId="endTimeInput">
                <Form.Label>Hora de Término</Form.Label>
                <Form.Control
                  type="time"
                  value={selectedPeriod.end_time}
                  onChange={(e) =>
                    setSelectedPeriod((prev) => ({
                      ...prev,
                      end_time: e.target.value,
                    }))
                  }
                />
              </Form.Group>

              <Button
                variant="danger"
                type="submit"
                style={{ marginTop: "10px" }}
              >
                Salvar Alterações
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        {/* Modal para editar descrição */}
        <Modal
          centered
          size="lg"
          show={showEditModal}
          onHide={handleCloseEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Editar Descrição</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="editDescription">
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={editDescription}
                  onChange={(e) => setEditDescription(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseEditModal}>
              Fechar
            </Button>
            <Button variant="danger" onClick={handleEditSubmit}>
              Salvar Mudanças
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal para adicionar nova atividade e período */}
        <Modal centered show={showAddModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {step === 1 ? "Adicionar Nova Atividade" : "Adicionar Período"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {step === 1 ? (
              <Form>
                <Form.Group controlId="newActivityDescription">
                  <Form.Label>Descrição da Atividade</Form.Label>
                  <Form.Control
                    type="text"
                    value={newActivityDescription}
                    onChange={(e) => setNewActivityDescription(e.target.value)}
                  />
                </Form.Group>
              </Form>
            ) : (
              <Form>
                <Form.Group controlId="classSelect">
                  <Form.Label>Selecione a Turma</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedClass}
                    onChange={handleClassChange}
                  >
                    <option value="">Selecione uma turma</option>
                    {classes.map((classItem) => (
                      <option key={classItem.id} value={classItem.id}>
                        {classItem.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="period">
                  <Form.Label>Período</Form.Label>
                  <Form.Control
                    as="select"
                    value={newPeriod.period}
                    onChange={(e) =>
                      setNewPeriod({ ...newPeriod, period: e.target.value })
                    }
                  >
                    <option value="">Selecione o período</option>
                    <option value="Manhã">Manhã</option>
                    <option value="Meio-dia">Meio-dia</option>
                    <option value="Tarde">Tarde</option>
                    <option value="Noite">Noite</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="startTime">
                  <Form.Label>Hora de Início</Form.Label>
                  <Form.Control
                    type="time"
                    value={newPeriod.start_time}
                    onChange={(e) =>
                      setNewPeriod({ ...newPeriod, start_time: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group controlId="endTime">
                  <Form.Label>Hora de Término</Form.Label>
                  <Form.Control
                    type="time"
                    value={newPeriod.end_time}
                    onChange={(e) =>
                      setNewPeriod({ ...newPeriod, end_time: e.target.value })
                    }
                  />
                </Form.Group>
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowAddModal(false)}>
              Cancelar
            </Button>
            {step === 1 ? (
              <Button variant="danger" onClick={handleAddActivity}>
                Próximo
              </Button>
            ) : (
              <Button variant="danger" onClick={handleAddPeriod}>
                Adicionar Período
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
      <Footer />
    </div>
  );
};

export default PlanoEnsino;
