import React, { useState } from "react";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import Footer from "../Components/Footer";
import env from "../utils/env";
import { useAuth } from "../Context/AuthContext";
import ToastAlert from "../utils/ToastAlert";
import icons96 from "../assets/img/icons8-user-96.png";
const AddEmployee = () => {
  // State variables for form inputs

  const { user } = useAuth();
  const [employee, setEmployee] = useState({
    // district_id: "",
    first_name: "",
    last_name: "",
    date_of_birth: "",
    gender: "",
    address: "",
    phone: "",
    picture_url: "",
    email: "",
    status: "",
    check_in_time: "",
    check_out_time: "",
    position: "",
    other_position: "",
    termination_date: "",
    hire_date: "",
    salary: "",
  });


  const [isOtherPosition, setIsOtherPosition] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmployee((prev) => ({ ...prev, [name]: value }));
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setEmployee((prevData) => ({
          ...prevData,
          full_picture_url: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePositionChange = (e) => {
    const value = e.target.value;
    setEmployee((prevData) => ({
      ...prevData,
      position: value,
      other_position: value === "Outro" ? prevData.other_position : "",
    }));
    setIsOtherPosition(value === "Outro");
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    try {
      const response = await fetch(
        `${env.base_url}schools/${user.school.id}/employees`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(employee),
        }
      );

      console.log("Resposta:", response);

      const { message, errors } = await response.json();
      if (response.ok) {
        setEmployee({
          district_id: "",
          first_name: "",
          last_name: "",
          date_of_birth: "",
          gender: "",
          address: "",
          phone: "",
          picture_url: "",
          email: "",


          check_in_time: "",
          check_out_time: "",
          position: "",
          other_position: "",
          contract_type: "",
          termination_date: "",
          hire_date: "",
          salary: "",
        });
        ToastAlert({ icon: "success", title: "Success", text: message });
      } else {
        ToastAlert({ icon: "error", title: "Error", text: errors });
      }
    } catch (error) {
      console.error("Erro ao salvar os dados do funcionário:", error);
    }
  };

  return (
    <div className="page-wrapper">
      <Header />
      <Sidebar />

      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Adicionar Colaborador</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="teachers.html">Colaboradores</a>
                </li>
                <li className="breadcrumb-item active">
                  Adicionar Colaborador
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12">
                      <h5 className="form-title">
                        <span>Dados Pessoais</span>
                      </h5>
                    </div>
                    <div
                      className="profile-picture mb-5"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src={employee.full_picture_url || icons96} // Placeholder image
                        alt="Avatar Preview"
                        className="avatar"
                        name="full_picture_url"
                        id="full_picture_url"
                        style={{
                          borderRadius: "50%",
                          width: "100px",
                          height: "100px",
                        }}
                      />
                      <label
                        htmlFor="upload-photo"
                        className="camera-icon"
                        style={{
                          position: "relative",
                          top: "70px",
                          right: "30px",
                          cursor: "pointer",
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "red",
                        }}
                      >
                        <i
                          className="fas fa-camera"
                          style={{ fontSize: "15px", color: "white" }}
                        ></i>
                      </label>
                      <input
                        type="file"
                        id="upload-photo"
                        accept="image/*"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                      />
                    </div>

                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>
                          Nome <span className="login-danger">*</span>
                        </label>
                        <input
                          type="text"
                          name="first_name"
                          className="form-control"
                          placeholder="Enter Name"
                          value={employee.first_name}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>
                          Apelido <span className="login-danger">*</span>
                        </label>
                        <input
                          type="text"
                          name="last_name"
                          className="form-control"
                          placeholder="Enter Name"
                          value={employee.last_name}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>
                          Email <span className="login-danger">*</span>
                        </label>
                        <input
                          name="email"
                          type="email"
                          className="form-control"
                          placeholder="email"
                          value={employee.email}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>
                          Genero <span className="login-danger">*</span>
                        </label>
                        <select
                          name="gender"
                          className="form-control select"
                          value={employee.gender}
                          onChange={handleInputChange}
                        >
                          <option value="">Selecione uma posição</option>
                          <option value="M">Masculino</option>
                          <option value="F">Feminino</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>
                          Data de Nascimento{" "}
                          <span className="login-danger">*</span>
                        </label>
                        <input
                          name="date_of_birth"
                          className="form-control datetimepicker"
                          type="date"
                          value={employee.date_of_birth}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>
                          Celular <span className="login-danger">*</span>
                        </label>
                        <input
                          type="text"
                          name="phone"
                          className="form-control"
                          placeholder="Enter Phone"
                          value={employee.phone}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>
                          Morada <span className="login-danger">*</span>
                        </label>
                        <input
                          name="address"
                          type="text"
                          className="form-control"
                          placeholder="Moradia"
                          value={employee.address}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <h5 className="form-title">
                        <span>Additional Information</span>
                      </h5>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms ">
                        <label>
                          Data de Contratação{" "}
                          <span className="login-danger">*</span>
                        </label>
                        <input
                          name="hire_date"
                          className="form-control datetimepicker"
                          type="date"
                          value={employee.hire_date}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-4 local-forms">
                      <div className="form-group">
                        <label>
                          Posicao <span className="login-danger">*</span>
                        </label>
                        <select
                          name="position"
                          className="form-control"
                          value={employee.position}
                          onChange={handlePositionChange}
                        >
                          <option value="">Selecione uma posição</option>
                          <option value="Educadores">Educadores</option>
                          <option value="Professores">Professores</option>
                          <option value="Outro">Outro</option>
                        </select>
                        {isOtherPosition && (
                          <input
                            name="other_position"
                            className="form-control mt-2"
                            type="text"
                            placeholder="Digite outra posição"
                            value={employee.other_position}
                            onChange={handleInputChange}
                          />
                        )}
                      </div>
                    </div>
                    {/* <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>
                          Tipo de contrato{" "}
                          <span className="login-danger">*</span>
                        </label>
                        <input
                          name="contract_type"
                          type="text"
                          className="form-control"
                          placeholder="Enter Contract Type"
                          value={employee.contract_type}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div> */}
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>
                          Salario <span className="login-danger">*</span>
                        </label>
                        <input
                          name="salary"
                          type="text"
                          className="form-control"
                          placeholder="Enter salary"
                          value={employee.salary}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>
                          Horario de check-in
                          <span className="login-danger">*</span>
                        </label>
                        <input
                          name="check_in_time"
                          type="text"
                          className="form-control"
                          placeholder="Enter Contract Type"
                          value={employee.check_in_time}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>
                          Horario de check-out{" "}
                          <span className="login-danger">*</span>
                        </label>
                        <input
                          name="check_out_time"
                          type="text"
                          className="form-control"
                          placeholder="Enter Contract Type"
                          value={employee.check_out_time}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>
                          Termino Contrato{" "}
                          <span className="login-danger">*</span>
                        </label>
                        <input
                          name="termination_date"
                          className="form-control datetimepicker"
                          type="date"
                          placeholder="DD-MM-YYYY"
                          value={employee.termination_date}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>
                          Status <span className="login-danger">*</span>
                        </label>
                        <select
                          name="status"
                          className="form-control select"
                          value={employee.status}
                          onChange={handleInputChange}
                        >
                          <option value="">Selecione um Status</option>
                          <option value="Activo">Activo</option>
                          <option value="Inativo">Inativo</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-group">
                        <button type="submit" className="btn btn-primary">
                          Add Employee
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AddEmployee;
