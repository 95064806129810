import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import env from "../utils/env";
import { useAuth } from "../Context/AuthContext";
import ToastAlert from "../utils/ToastAlert";
const Profile = () => {
  const [userProfile, setUserProfile] = useState({});
  const { user } = useAuth();
  const [loading, setLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [userSecurityData, setUserSecurityData] = useState({
    password: "",
    old_password: "",
  });

  useEffect(() => {
    // Função para buscar dados do perfil
    const token = localStorage.getItem("token");
    const fetchUserProfile = async () => {
      try {
        const response = await fetch(`${env.base_url}accounts/${user.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        if (response.ok) {
          const { data } = await response.json();
          setUserProfile(data);
        } else {
          console.log("Erro ao buscar perfil");
        }
      } catch (error) {
       console.error("Erro ao buscar perfil:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [user.id]);

  const handleUpload = async (file) => {
    if (!file) return;

    setUploading(true);
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("picture", file);

    try {
      const response = await fetch(
        `${env.base_url}accounts/${user.id}/profile-picture`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      const { message, errors } = await response.json();

      if (response.ok) {
        ToastAlert({ icon: "success", title: "success", text: message });
        const { data } = await response.json();
        setUserProfile((prevProfile) => ({
          ...prevProfile,
          picture: data.full_picture_url, // Atualize com o URL retornado pela API
        }));
      } else {
        ToastAlert({ icon: "error", title: "success", text: errors });
      }
    } catch (error) {
      console.error("Erro ao enviar imagem:", error);
    } finally {
      setUploading(false);
    }
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setUserProfile((prevProfile) => ({
          ...prevProfile,
          picture: reader.result,
        }));
        // Envia a imagem automaticamente após a seleção
        handleUpload(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChangeSecurity = (e) => {
    const { name, value } = e.target;
    setUserSecurityData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangePassword = async (e) => {
    // Lógica para alterar senha
    e.preventDefault();
    const token = localStorage.getItem("token");
    setLoading(true);
    try {
      
      const responseSecurity = await fetch(
        env.base_url + `accounts/${user.id}/change-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(userSecurityData),
        }
      );
      const { message, errors } = await responseSecurity.json();
      if (responseSecurity.ok) {
        ToastAlert({ icon: "success", title: "success", text: message });
        const json_response = await responseSecurity.json();
        setUserSecurityData(json_response.data);
        setUserSecurityData({
            password: "",
            old_password: ""
          });
        setLoading(false);
      } else {
        ToastAlert({ icon: "error", title: "success", text: errors });
        setLoading(false);
      }
    } catch (error) {
      console.error("Erro ao atualizar senha:", error);
      setLoading(false);
    }
  };



  return (
    <div className="page-wrapper">
      <Header />
      <Sidebar />
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col">
              <h3 className="page-title">Profile</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="index.html">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">Profile</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="profile-header">
              <div className="row align-items-center">
                <div className="col-auto profile-image">
                  <a href="/">
                    <img
                      className="rounded-circle"
                      alt={user.first_name}
                      src={userProfile.full_picture_url}
                    />
                  </a>
                </div>
                <div className="col ms-md-n2 profile-user-info">
                  <h4 className="user-name mb-0">
                    {user.first_name + " " + user.last_name}
                  </h4>
                </div>
                <div
                  className="mt-2"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <label
                    htmlFor="logo"
                    className="btn"
                    style={{
                      backgroundColor: "red",
                      borderRadius: "5px",
                      padding: "5px 10px",
                      color: "white",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <i className="fa-solid fa-camera"></i> Trocar imagem
                  </label>
                  <input
                    type="file"
                    id="logo"
                    name="logo"
                    accept="image/*"
                    onChange={handlePhotoChange}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
            </div>

            <div className="profile-menu">
              <ul className="nav nav-tabs nav-tabs-solid">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-bs-toggle="tab"
                    href="#per_details_tab"
                  >
                    Detalhes Pessoais
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    href="#password_tab"
                  >
                    Senha
                  </a>
                </li>
              </ul>
            </div>

            <div className="tab-content profile-tab-cont">
              <div className="tab-pane fade show active" id="per_details_tab">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <h5 className="card-title d-flex justify-content-between">
                          <span></span>
                          <a
                            className="edit-link"
                            data-bs-toggle="modal"
                            href="#edit_personal_details"
                          >
                            <i className="far fa-edit me-1"></i>Edit
                          </a>
                        </h5>
                        <div className="row">
                          <p className="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                            Nome
                          </p>
                          <p className="col-sm-9">
                            {userProfile.first_name +
                              " " +
                              userProfile.last_name}
                          </p>
                        </div>
                        <div className="row">
                          <p className="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                            Email
                          </p>
                          <p className="col-sm-9">
                            <a href={`mailto:${userProfile.email}`}>
                              {userProfile.email || "[email protected]"}
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="password_tab" className="tab-pane fade">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title mb-4">Mudar Senha</h5>
                    <div className="row">
                      <div className="col-md-10 col-lg-6">
                        <form onSubmit={handleChangePassword}>
                          <div className="form-group">
                            <label>Senha Antiga</label>
                            <input
                              type="password"
                              className="form-control"
                              onChange={handleChangeSecurity}
                              name="old_password"
                              value={userSecurityData.old_password}
                            />
                          </div>
                          <div className="form-group">
                            <label>Nova Senha</label>
                            <input
                              type="password"
                              className="form-control"
                              onChange={handleChangeSecurity}
                              name="password"
                              value={userSecurityData.password}
                            />
                          </div>
                          <button
                            className="btn btn-danger"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? "A carregar..." : "Atualizar"}
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
