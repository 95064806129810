import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import Footer from "../Components/Footer";
import ExtraLessonModal from "../Components/ExtraLessonModal";
import { Button } from "react-bootstrap";
import env from "../utils/env";
import { useAuth } from "../Context/AuthContext";
import ToastAlert from "../utils/ToastAlert";

const Extralessons = () => {
  const [sportsData, setSportsData] = useState([]);
  const { user } = useAuth();
  const [modalShow, setModalShow] = useState(false);
  const [modalMode, setModalMode] = useState("view"); // 'view', 'edit', 'add'
  const [selectedSport, setSelectedSport] = useState(null);

  const handleModalOpen = (mode, sport = null) => {
    setModalMode(mode);
    setSelectedSport(sport);
    setModalShow(true);
  };

  const handleModalClose = () => setModalShow(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${env.base_url}schools/${user.school.id}/extracurricular-subjects`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        const { data } = await response.json();
        setSportsData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [user]);

  const handleSave = async (data) => {
    try {
      let response;
      const apiUrl = `${env.base_url}schools/${user.school.id}/extracurricular-subjects`;
      const requestOptions = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      };
  
      if (modalMode === "add") {
        response = await fetch(apiUrl, {
          ...requestOptions,
          method: "POST",
        });
      } else if (modalMode === "edit" && selectedSport?.id) {
        response = await fetch(`${apiUrl}/${selectedSport.id}`, {
          ...requestOptions,
          method: "PUT",
        });
      } else {
        throw new Error("Invalid mode or missing sport id");
      }
  
      const jsonResponse = await response.json();
  
      if (response.ok) {
        const { messages, data: updatedData } = jsonResponse;
        ToastAlert({ icon: "success", title: "Success", text: messages });
  
        if (modalMode === "add") {
          setSportsData([...sportsData, updatedData]);
        } else if (modalMode === "edit") {
          setSportsData(
            sportsData.map((sport) =>
              sport.id === updatedData.id ? updatedData : sport
            )
          );
        }
  
        handleModalClose();
      } else {
        const { errors } = jsonResponse;
        ToastAlert({ icon: "error", title: "Error", text: errors });
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };
  

  const handleDelete = async (id) => {
    
    window.confirm("Tem certeza de que deseja excluir esta classe extracurricular?");
    try {
      const response = await fetch(
        `${env.base_url}schools/${user.school.id}/extracurricular-subjects/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
  
      const jsonResponse = await response.json();
      if (response.ok) {
        const { messages } = jsonResponse;
        setSportsData(sportsData.filter((sport) => sport.id !== id));
        ToastAlert({ icon: "success", title: "Success", text: messages });
      } else {
        const { errors } = jsonResponse;
        ToastAlert({ icon: "error", title: "Error", text: errors });
      }
    } catch (error) {
      console.error("Error deleting data:", error);
      ToastAlert({ icon: "error", title: "Error", text: "Failed to delete data." });
    }
  };
  

  const downloadCSV = () => {
    const headers = ["Name", "Professor", "Preco", "Descricao"];
    const rows = sportsData.map((sport) => [
      sport.name,
      sport.teacher,
      sport.price,
      sport.description,
    ]);

    let csvContent =
      "data:text/csv;charset=utf-8," +
      headers.join(",") +
      "\n" +
      rows.map((e) => e.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "dados_extracurricular.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="page-wrapper">
      <Header />
      <Sidebar />
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Extracurricular</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="index.html">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">Extracurricular</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table">
              <div className="card-body">
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col-auto text-end float-end ms-auto download-grp">
                      <Button
                        className="btn btn-outline-primary me-2"
                        onClick={downloadCSV}
                      >
                        <i className="fas fa-download"></i> Download
                      </Button>
                      <Button
                        className="btn btn-primary"
                        onClick={() => handleModalOpen("add")}
                      >
                        <i className="fas fa-plus"></i>
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                    <thead className="student-thread">
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Professor</th>
                        <th>Preco</th>
                        <th>Descricao</th>
                        <th className="text-end">Accoes</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sportsData && sportsData.map((sport, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {sport.name}
                          </td>
                          <td>{sport.teacher}</td>
                          <td>{sport.price}</td>
                          <td className="truncated-text">
                            {sport.description}
                          </td>
                          <td className="text-end">
                            <div className="actions">
                              <Button
                                className="btn btn-sm btn-success me-1"
                                onClick={() => handleModalOpen("view", sport)}
                                title="View Details"
                              >
                                <i className="fas fa-eye"></i>
                              </Button>
                              <Button
                                className="btn btn-sm btn-warning me-1"
                                onClick={() => handleModalOpen("edit", sport)}
                                title="Edit"
                              >
                                <i
                                  className="fas fa-edit"
                                  style={{ color: "white" }}
                                ></i>
                              </Button>
                              <Button
                                className="btn btn-sm btn-danger"
                                onClick={() => handleDelete(sport.id)}
                                title="Delete"
                              >
                                <i className="fas fa-trash"></i>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <ExtraLessonModal
        show={modalShow}
        handleClose={handleModalClose}
        mode={modalMode}
        sport={selectedSport}
        handleSave={handleSave}
      />
    </div>
  );
};

export default Extralessons;
