import React from 'react';
import { useState } from 'react';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Footer from '../Components/Footer';
import avatar_01 from '../assets/img/profiles/avatar-01.jpg';
import avatar_02 from '../assets/img/profiles/avatar-02.jpg';
import avatar_03 from '../assets/img/profiles/avatar-03.jpg';   
import avatar_04 from '../assets/img/profiles/avatar-04.jpg';
import avatar_05 from '../assets/img/profiles/avatar-05.jpg';
import avatar_06 from '../assets/img/profiles/avatar-06.jpg';
import avatar_07 from '../assets/img/profiles/avatar-07.jpg';
import avatar_08 from '../assets/img/profiles/avatar-08.jpg';
import avatar_09 from '../assets/img/profiles/avatar-09.jpg';
import avatar_10 from '../assets/img/profiles/avatar-10.jpg';
import avatar_11 from '../assets/img/profiles/avatar-11.jpg';

const SalaryPage = () => {
  const [salaries, setSalaries] = useState([
    { id: 'PRE2209', name: 'Aaliyah', gender: 'Female', joiningDate: '17 Aug 2020', amount: '$320', status: 'Paid', avatar: avatar_01 },
    { id: 'PRE2213', name: 'Malynne', gender: 'Female', joiningDate: '05 Aug 2020', amount: '$536', status: 'Paid', avatar: avatar_02 },
    { id: 'PRE2143', name: 'Levell Scott', gender: 'Male', joiningDate: '04 Sept 2020', amount: '$378', status: 'Paid', avatar: avatar_03 },
    { id: 'PRE2431', name: 'Minnie', gender: 'Female', joiningDate: '17 Sept 2020', amount: '$246', status: 'Unpaid', avatar: avatar_04 },
    { id: 'PRE1534', name: 'Lois A', gender: 'Male', joiningDate: '02 Oct 2020', amount: '$560', status: 'Unpaid', avatar: avatar_05 },
    { id: 'PRE2153', name: 'Calvin', gender: 'Male', joiningDate: '28 Oct 2020', amount: '$236', status: 'Unpaid', avatar: avatar_06 },
    { id: 'PRE1252', name: 'Joe Kelley', gender: 'Female', joiningDate: '17 Oct 2020', amount: '$237', status: 'Paid', avatar: avatar_07 },
    { id: 'PRE1434', name: 'Vincent', gender: 'Male', joiningDate: '05 Nov 2020', amount: '$567', status: 'Paid', avatar: avatar_08 },
    { id: 'PRE2345', name: 'Kozma Tatari', gender: 'Female', joiningDate: '12 Nov 2020', amount: '$564', status: 'Paid', avatar: avatar_09 },
    { id: 'PRE2365', name: 'John Chambers', gender: 'Male', joiningDate: '15 Nov 2020', amount: '$234', status: 'Paid', avatar: avatar_10 },
    { id: 'PRE1234', name: 'Nathan Humphries', gender: 'Male', joiningDate: '17 Nov 2020', amount: '$278', status: 'Paid', avatar: avatar_11 },
  ]);

  return (
    <div className="page-wrapper">
      <Header />
      <Sidebar />
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Salary</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item"><a href="index.html">Dashboard</a></li>
                <li className="breadcrumb-item active">Salary</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table">
              <div className="card-body">
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col">
                      <h3 className="page-title">Salary</h3>
                    </div>
                    <div className="col-auto text-end float-end ms-auto download-grp">
                      <a href="salary.html#" className="btn btn-outline-primary me-2"><i className="fas fa-download"></i> Download</a>
                      <a href="/add-salary" className="btn btn-primary"><i className="fas fa-plus"></i></a>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                    <thead className="student-thread">
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Gender</th>
                        <th>Joining Date</th>
                        <th>Amount</th>
                        <th className="text-end">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {salaries.map((salary) => (
                        <tr key={salary.id}>
                          <td>{salary.id}</td>
                          <td>
                            <h2 className="table-avatar">
                              <a className="avatar avatar-sm me-2" href="/#"><img className="avatar-img rounded-circle" src={salary.avatar} alt="UserImage" /></a>
                              <a href="profile.html">{salary.name}</a>
                            </h2>
                          </td>
                          <td>{salary.gender}</td>
                          <td>{salary.joiningDate}</td>
                          <td>{salary.amount}</td>
                          <td className="text-end">
                            <span className={`badge ${salary.status === 'Paid' ? 'badge-success' : 'badge-danger'}`}>{salary.status}</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
};

export default SalaryPage;
