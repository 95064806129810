import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Footer from '../Components/Footer';
import avatar_01 from '../assets/img/profiles/avatar-01.jpg';
import avatar_02 from '../assets/img/profiles/avatar-02.jpg';
import avatar_03 from '../assets/img/profiles/avatar-03.jpg';   
import avatar_04 from '../assets/img/profiles/avatar-04.jpg';
import avatar_05 from '../assets/img/profiles/avatar-05.jpg';
import avatar_06 from '../assets/img/profiles/avatar-06.jpg';
import avatar_07 from '../assets/img/profiles/avatar-07.jpg';
import avatar_08 from '../assets/img/profiles/avatar-08.jpg';
import avatar_09 from '../assets/img/profiles/avatar-09.jpg';
import avatar_10 from '../assets/img/profiles/avatar-10.jpg';

const ClassDetails = () => {
  const { id } = useParams();
//   const [students, setStudents] = useState([]);
  const studentsData = [
    {
      id: 'PRE2209',
      name: 'Aaliyah',
      class: '10 A',
      dob: '2 Feb 2002',
      parentName: 'Jeffrey Wong',
      mobile: '097 3584 5870',
      address: '911 Deer Ridge Drive, USA',
      avatar: avatar_01,
    },
    {
      id: 'PRE2213',
      name: 'Malynne',
      class: '8 A',
      dob: '3 June 2010',
      parentName: 'Fields Malynne',
      mobile: '242 362 3100',
      address: 'Bacardi Rd P.O. Box N-4880, New Providence',
      avatar: avatar_02,
    },
    {
      id: 'PRE2143',
      name: 'Levell Scott',
      class: '10 A',
      dob: '12 Apr 2002',
      parentName: 'Jeffrey Scott',
      mobile: '026 7318 4366',
      address: 'P.O. Box: 41, Gaborone',
      avatar: avatar_03,
    },
    {
      id: 'PRE2431',
      name: 'Minnie',
      class: '11 C',
      dob: '24 Feb 2000',
      parentName: 'J Shaffer',
      mobile: '952 512 4909',
      address: '4771 Oral Lake Road, Golden Valley',
      avatar: avatar_04
    },
    {
      id: 'PRE1534',
      name: 'Lois A',
      class: '10 A',
      dob: '22 Jul 2006',
      parentName: 'Cleary Wong',
      mobile: '413 289 1314',
      address: '2844 Leverton Cove Road, Palmer',
      avatar: avatar_05
    },
    {
      id: 'PRE2153',
      name: 'Calvin',
      class: '9 B',
      dob: '8 Dec 2003',
      parentName: 'Minnie J Shaffer',
      mobile: '701 753 3810',
      address: '1900 Hidden Meadow Drive, Crete',
      avatar: avatar_05
    },
    {
      id: 'PRE1252',
      name: 'Joe Kelley',
      class: '11 C',
      dob: '7 Oct 2000',
      parentName: 'Vincent Howard',
      mobile: '402 221 7523',
      address: '3979 Ashwood Drive, Omaha',
      avatar: avatar_06
    },
    {
      id: 'PRE1434',
      name: 'Vincent',
      class: '10 A',
      dob: '4 Jan 2002',
      parentName: 'Kelley Joe',
      mobile: '402 221 7523',
      address: '3979 Ashwood Drive, Omaha',
      avatar: avatar_07
    },
    {
      id: 'PRE2345',
      name: 'Kozma Tatari',
      class: '9 A',
      dob: '1 Feb 2006',
      parentName: 'Lombardi',
      mobile: '04 2239 968',
      address: 'Rruga E Kavajes, Condor Center, Tirana',
      avatar: avatar_08
    },
    {
      id: 'PRE2365',
      name: 'John Chambers',
      class: '11 B',
      dob: '13 Sept 2003',
      parentName: 'Wong Jeffrey',
      mobile: '870 663 2334',
      address: '4667 Sunset Drive, Pine Bluff',
      avatar: avatar_09
    },
    {
      id: 'PRE1234',
      name: 'Nathan Humphries',
      class: '10 B',
      dob: '26 Apr 1994',
      parentName: 'Stephen Marley',
      mobile: '077 3499 9959',
      address: '86 Lamphey Road, Thelnetham',
      avatar: avatar_10
    }
  ]

//   useEffect(() => {
//     // Supondo que você tenha uma API para buscar os estudantes de uma turma específica
//     const fetchStudents = async () => {
//       try {
//         // Substitua esta linha com a chamada real à API
//         // const response = await fetch(`https://api.example.com/classes/${id}/students`);
//         // const data = await response.json();

//         // Para fins de demonstração, usaremos os dados estáticos
//         const data = studentsData.filter(student => student.class === `Class ${id}`);
//         setStudents(data);
//       } catch (error) {
//         console.error('Error fetching students:', error);
//       }
//     };

//     fetchStudents();
//   }, []);

  return (
    <div className="page-wrapper">
      <Header />
      <Sidebar />
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-sub-header">
                <h3 className="page-title">Estudantes da Turma {id}</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/classes">Turmas</a></li>
                  <li className="breadcrumb-item active">Estudantes</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table comman-shadow">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                    <thead className="student-thread">
                      <tr>
                        <th>ID</th>
                        <th>Nome</th>
                        <th>Classe</th>
                        <th>Data de Nascimento</th>
                        <th>Nome do Responsável</th>
                        <th>Número de Telefone</th>
                        <th>Endereço</th>
                        <th className="text-end">Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      {studentsData.map((student, index) => (
                        <tr key={index}>
                          <td>{student.id}</td>
                          <td>
                            <h2 className="table-avatar">
                              <a href="student-details.html" className="avatar avatar-sm me-2">
                                <img className="avatar-img rounded-circle" src={student.avatar} alt="UserImage" />
                              </a>
                              <a href="student-details.html">{student.name}</a>
                            </h2>
                          </td>
                          <td>{student.class}</td>
                          <td>{student.dob}</td>
                          <td>{student.parentName}</td>
                          <td>{student.mobile}</td>
                          <td>{student.address}</td>
                          <td className="text-end">
                            <div className="actions">
                              <a href={`edit-student/${student.id}`} className="btn btn-sm bg-danger-light">
                                <i className="fa-regular fa-pen-to-square"></i>
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ClassDetails;
