import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import env from "../utils/env";
import { useAuth } from "../Context/AuthContext";
import ToastAlert from "../utils/ToastAlert";
import PostCard from "../Components/PostCard";
import { Modal } from "react-bootstrap";

const Gallery = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    full_logo_url: "",
    full_cover_url: "",
    phone: "",
    address: "",
    city: "",
    description: "",
    province: { name: "", id: "" },
    district: { name: "", id: "" },
    info_email: "",
    support_email: "",
    open_time: "",
    close_time: "",
    operating_hours: "",
    capacity: "",
    duration: "",
    classrooms: "",
    education_type: "",
    age_range: "",
    fees: "",
    enrollment_fee: "",
    enrollment_contact: "",
    user_id: user.id,
    id: "",
    logo: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [images, setImages] = useState([]);
  const closeModal = () => {
    setShowModal(false);
    setSelectedFile(null);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };
  // funcao carregar as fotos

  const fetchimagesData = async () => {
    try {
      const response = await fetch(
        `${env.base_url}schools/${user.school.id}/pictures`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const { data } = await response.json();
      setImages(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    

    // Função para buscar os dados do centro infantil

    const fetchData = async () => {
      try {
        const response = await fetch(
          env.base_url + `accounts/${user.id}/school`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        const { data } = await response.json();
        setFormData({
          name: data.name || "",
          full_logo_url: data.full_logo_url || "",
          full_cover_url: data.full_cover_url || "",
          address: data.address || "",
          city: data.city || "",
          provinces: data.provinces || "",
          phone: data.phone || "",
          province: data.province || { name: "", id: "" },
          district: data.district || { name: "", id: "" },
          country: data.country || "",
          open_time: data.open_time || "",
          close_time: data.close_time || "",
          operating_hours: data.operating_hours || "",
          age_range: data.age_range || "",
          capacity: data.capacity || "",
          description: data.description || "",
          info_email: data.info_email || "",
          support_email: data.support_email || "",
          classrooms: data.classrooms || "",
          education_type: data.education_type || "",
          duration: data.duration || "",
          districts: data.districts || "",
          enrollment_fee: data.enrollment_fee || "",
          enrollment_open: data.enrollment_open || "",
          fees: data.fees || "",
          enrollment_contact: data.enrollment_contact || "",
          user_id: user.id,
          email: data.email || "",
          id: data.id || "",
          logo: "",
        });
      } catch (error) {
        console.error("Erro ao buscar os dados:", error);
      }
    };

    fetchData();
    fetchimagesData();
  }, [user.id, user.school.id]);

  const handlePostImageUpload = async () => {
    setLoading(true); // Inicia o carregamento
    const formDataToSend = new FormData();
    formDataToSend.append("image", selectedFile); // Envia a imagem como data URL

    try {
      const response = await fetch(
        `${env.base_url}schools/${user.school.id}/pictures`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
          },
          body: formDataToSend,
        }
      );
      const { message, errors } = await response.json();
      if (response.ok) {
        ToastAlert({ icon: "success", title: "success", text: message });
        setSelectedFile(null);
        setShowModal(false);
        await fetchimagesData(); // Atualiza a lista de imagens
      } else {
        ToastAlert({ icon: "error", title: "error", text: errors });
      }
    } catch (error) {
      console.error("Erro ao enviar a postagem:", error);
    } finally {
      setLoading(false); // Finaliza o carregamento
    }
  };

  const handleRemovePhoto = async (photoId) => {
    try {
      const response = await fetch(
        `${env.base_url}schools/${user.school.id}/pictures/${photoId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
          },
        }
      );
      const { message, errors } = await response.json();

      if (response.ok) {
        ToastAlert({ icon: "success", title: "success", text: message });
        setImages((prevImages) =>
          prevImages.filter((photo) => photo.id !== photoId)
        );
        setFormData((prevState) => ({
          ...prevState,
          pictures: prevState.pictures
            ? prevState.pictures.filter((photo) => photo.id !== photoId)
            : [],
        }));
      } else {
        ToastAlert({ icon: "error", title: "error", text: errors });
      }
    } catch (error) {
      console.error("Erro ao excluir a foto:", error);
    }
  };

  return (
    <div className="main-wrapper">
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-12">
              <PostCard
                images={images}
                formData={formData}
                handleRemovePhoto={handleRemovePhoto}
                openModal={openModal}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal centered show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Carregar Imagem</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {selectedFile ? (
              <img
                src={URL.createObjectURL(selectedFile)}
                alt="Pré-visualização"
                className="preview-img"
                style={{ width: "100%", height: "auto" }}
              />
            ) : (
              <>
                <i
                  className="fas fa-cloud-upload-alt"
                  style={{ fontSize: "100px", opacity: 0.5 }}
                ></i>
                <p style={{ opacity: 0.5 }}>Clique para carregar uma imagem</p>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileUpload}
                />
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger" onClick={handlePostImageUpload} disabled={loading}>
            {loading ? "A carregar..." : "Carregar"}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Gallery;
