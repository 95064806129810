import React, { useState } from 'react';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Footer from '../Components/Footer';

const AddSubject = () => {
  const [subject, setSubject] = useState('');
  const [professor, setProfessor] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newClass = {
      subject: subject,
      professor: professor,
    };

    try {
      const response = await fetch('https://api.example.com/classes', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newClass),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Class added:', data);
        // Reset form fields
        setSubject('');
        setProfessor('');
      } else {
        console.error('Error adding class');
      }
    } catch (error) {
      console.error('Error adding class:', error);
    }
  };

  return (
    <div className="page-wrapper">
        <Header/>
        <Sidebar/>
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Adicionar Disciplina</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item"><a href="classes.html">Disciplinas</a></li>
                <li className="breadcrumb-item active">Adicionar Disciplina</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <label>Disciplina</label>
                        <select
                          className="form-control select"
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                          required
                        >
                          <option value="">Selecione a Disciplina</option>
                          <option value="Matematica">Matematica</option>
                          <option value="Linguagem">Linguagem</option>
                          <option value="Etica">Etica</option>
                          
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <label>Professor Responsável</label>
                        <input
                          type="text"
                          className="form-control"
                          value={professor}
                          onChange={(e) => setProfessor(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <button type="submit" className="btn btn-primary">Enviar</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
};

export default AddSubject;
