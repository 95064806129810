import React from "react";

const Footer = () => {
  return (
    <footer>
      <p>COPYRIGHT © 2024 CENTRO-INFANTIL.</p>
    </footer>
  );
};

export default Footer;
