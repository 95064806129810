import React, { useState, useEffect } from "react";
import env from "../utils/env";
import { useAuth } from "../Context/AuthContext";
import { Modal } from "react-bootstrap";
import ToastAlert from "../utils/ToastAlert";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Importa o estilo do Quill

const Classes = () => {
  const { user } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState([]); // Atualizado para array vazio
  const [newClass, setNewClass] = useState({
    name: "",
    description: "",
    amount: "",
    min_age: "",
    max_age: "",
  });
  const [editingClass, setEditingClass] = useState(null);

  const closeModal = () => {
    setShowModal(false);
    setNewClass({
      name: "",
      description: "",
      amount: "",
      min_age: "",
      max_age: "",
    });
    setEditingClass(null);
  };

  const openModal = (classData = null) => {
    if (classData) {
      setEditingClass(classData);
      setNewClass({
        name: classData.name || "",
        description: classData.description || "",
        amount: classData.amount || "",
        min_age: classData.min_age || "",
        max_age: classData.max_age || "",
      });
    } else {
      setEditingClass(null);
      setNewClass({
        name: "",
        description: "",
        amount: "",
        min_age: "",
        max_age: "",
      });
    }
    setShowModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewClass((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDescriptionChange = (value) => {
    setNewClass((prev) => ({
      ...prev,
      description: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const url = editingClass
      ? `${env.base_url}schools/${user.school.id}/classes/${editingClass.id}`
      : `${env.base_url}schools/${user.school.id}/classes`;
    const method = editingClass ? "PUT" : "POST";

    try {
      const response = await fetch(url, {
        method,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(newClass),
      });

      const jsonResponse = await response.json();
      const { messages, errors } = jsonResponse;

      if (response.ok) {
        ToastAlert({ icon: "success", title: "Success", text: messages });
        setFormData((prev) =>
          editingClass
            ? prev.map((cls) =>
                cls.id === editingClass.id ? { ...cls, ...newClass } : cls
              )
            : [...prev, newClass]
        );
        closeModal();
      } else {
        ToastAlert({ icon: "error", title: "Error", text: errors });
      }
    } catch (error) {
      console.error("Erro ao salvar classe:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (classId) => {
    if (!classId) {
      console.error("ID da classe não fornecido para exclusão");
      return;
    }

    if (window.confirm("Tem certeza de que deseja deletar esta classe?")) {
      try {
        const response = await fetch(
          `${env.base_url}schools/${user.school.id}/classes/${classId}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        const { message, errors } = await response.json();
        if (response.ok) {
          ToastAlert({ icon: "success", title: "Success", text: message });
          setFormData((prev) => prev.filter((cls) => cls.id !== classId));
        } else {
          ToastAlert({ icon: "error", title: "Error", text: errors });
        }
      } catch (error) {
        console.error("Error deleting class:", error);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          env.base_url + `schools/${user.school.id}/classes`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        const { data } = await response.json();
        console.log(data);
        setFormData(data || []); // Garantir que seja um array
      } catch (error) {
        console.error("Erro ao buscar os dados:", error);
      }
    };

    fetchData();
  }, [user.id, user.school.id]);

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title">Classes</h5>
      </div>
      <div className="card-body pt-0" style={{ backgroundColor: "#f8f9fa" }}>
        <div className="class-section">
          <label
            htmlFor="postImage"
            className="btn btn-danger mt-3 mb-3"
            style={{
              borderRadius: "5px",
              padding: "5px 10px",
              marginRight: "10px",
              fontSize: "12px",
            }}
            onClick={() => openModal()}
          >
            <i className="fa-solid fa-plus"></i> Nova Classe
          </label>
          <div className="row mt-4">
            {formData &&
              formData.map((cls, index) => (
                <div className="col-md-4 mb-3" key={index}>
                  <div className="card" style={{ minHeight: "300px" }}>
                    <div className="card-header">
                      <h5
                        className="card-title"
                        style={{ textAlign: "center" }}
                      >
                        {cls.name}
                      </h5>
                    </div>
                    <div
                      className="card-body"
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        alignContent: "center",
                        padding: "10px",
                        margin: "10px",
                      }}
                    >
                      <div
                        className="class-description mb-3"
                        dangerouslySetInnerHTML={{ __html: cls.description }}
                      />
                      <p>
                        <strong>Faixa Etária:</strong> {cls.min_age} -{" "}
                        {cls.max_age} anos
                      </p>
                      <p>
                        <strong>Mensalidade:</strong> {cls.amount}
                      </p>
                      <div className="card-icons position-absolute bottom-0 end-0 p-2">
                        <button
                          className="btn btn-danger me-2"
                          title="Editar"
                          style={{
                            borderRadius: "5px",
                            padding: "5px 10px",
                            marginRight: "10px",
                            fontSize: "12px",
                          }}
                          onClick={() => openModal(cls)} // Passa a classe para a função de abertura do modal
                        >
                          <i className="fa-regular fa-pen-to-square"></i>
                        </button>
                        <button
                          className="btn btn-danger"
                          title="Excluir"
                          onClick={() => handleDelete(cls.id)}
                          style={{
                            borderRadius: "5px",
                            padding: "5px 10px",
                            marginRight: "10px",
                            fontSize: "12px",
                          }}
                        >
                          <i className="fa-solid fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <Modal centered size="xl" show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editingClass ? "Editar Classe" : "Criar Nova Classe"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="form-group mb-3">
              <label htmlFor="name">Nome da Classe:</label>
              <input
                type="text"
                id="name"
                name="name"
                className="form-control"
                value={newClass.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="description">Descrição:</label>
              <ReactQuill
                theme="snow"
                value={newClass.description}
                onChange={handleDescriptionChange}
                modules={EditorModules}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="amount">Mensalidade (MT):</label>
              <input
                type="number"
                id="amount"
                name="amount"
                className="form-control"
                value={newClass.amount}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="min_age">Idade Mínima:</label>
              <input
                type="number"
                id="min_age"
                name="min_age"
                className="form-control"
                value={newClass.min_age}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="max_age">Idade Máxima:</label>
              <input
                type="number"
                id="max_age"
                name="max_age"
                className="form-control"
                value={newClass.max_age}
                onChange={handleInputChange}
                required
              />
            </div>
            <button type="submit" className="btn btn-danger" disabled={loading}>
              {loading ? "Salvando..." : "Salvar"}
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const EditorModules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline"],
    ["link", "image"],
    ["clean"],
  ],
};

export default Classes;
