import React from 'react';
import { useAuth } from '../Context/AuthContext';



const Dashboard = () => {
  const {user} = useAuth();
  

  return (
    <div className="page-wrapper">
      <div className="container-fluid p-5" >
        <div className="page-header">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-sub-header">
                <h3 className="page-title">Welcome {user.first_name}</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="index.html">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Admin</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        
      </div>
      <footer>
          <p>COPYRIGHT © 2023 DREAMSTECHNOLOGIES.</p>
        </footer>
    </div>
  );
};

export default Dashboard;
