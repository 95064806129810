import { useState, useEffect } from 'react';

const INACTIVITY_TIMEOUT = 60 * 60 * 1000; // 1 hour

function useInactivityLogout() {
  const [timeoutId, setTimeoutId] = useState(null);

  const resetTimer = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const id = setTimeout(() => {
      // Perform logout or session cleanup
      localStorage.removeItem('token'); //  Remove token from localStorage
      localStorage.removeItem('user'); // Remove user from localStorage
      window.location.href = '/login'; // Redirect to login page
    }, INACTIVITY_TIMEOUT);
    setTimeoutId(id);
  };

  useEffect(() => {
    const events = ['mousemove', 'keydown', 'scroll', 'click'];

    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    resetTimer(); // Start the timer when the component mounts

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
      clearTimeout(timeoutId);
    };
  }, []);
}

export default useInactivityLogout;
