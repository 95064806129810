import React, { useState } from "react";
import { Container, Button, Form, Alert } from "react-bootstrap";
import env from "../utils/env";
import Finalize_MainIcon from "../assets/img/Finalize_MainIcon.png";
const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");
    setError("");

    try {
      const response = await fetch(`${env.base_url}auth/account/forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ email }),
      });
      console.log(response);

      if (response.ok) {
        
        setMessage(
          "Um e-mail com instruções para redefinir sua senha foi enviado."
        );
      } else {
        const errorData = await response.json();
        
        setError(
          errorData.errors ||
            "Falha ao enviar o e-mail de redefinição de senha."
        );
      }
    } catch (err) {
      setError(
        "Ocorreu um erro ao enviar o e-mail de redefinição de senha. Tente novamente mais tarde."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="vh-100 d-flex justify-content-center align-items-center">
      <div className="p-4 w-100" style={{ maxWidth: "400px" }}>
        <div className="d-flex justify-content-center align-items-center mb-3">
          <img
            src={process.env.PUBLIC_URL + Finalize_MainIcon}
            alt="Carregando..."
            style={{
              width: "15%",
              height: "auto",
              display: "flex",
            }}
          />
        </div>
        <h2 className="text-center mb-4 text-dark">Esqueceu a Senha?</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        {message && <Alert variant="success">{message}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="email" className="mb-3">
            <Form.Label></Form.Label>
            <Form.Control
              type="email"
              placeholder="Digite seu e-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>
          <Button
            variant="danger"
            type="submit"
            className="w-100 mt-3"
            disabled={loading}
          >
            {loading ? "Enviando..." : "Enviar E-mail"}
          </Button>
        </Form>
      </div>
    </Container>
  );
};

export default ForgotPassword;
