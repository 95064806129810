import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const ExtraLessonModal = ({ show, handleClose, mode, sport, handleSave }) => {
  const [formData, setFormData] = useState({
    name: "",
    teacher: "",
    price: "",
    description: "",
  });

  useEffect(() => {
    if (mode === "edit" || mode === "view") {
      if (sport) {
        setFormData({
          name: sport.name || "",
          teacher: sport.teacher || "",
          price: sport.price || "",
          description: sport.description || "",
        });
      }
    } else if (mode === "add") {
      setFormData({
        name: "",
        teacher: "",
        price: "",
        description: "",
      });
    }
  }, [mode, sport]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSave(formData);
  };

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {mode === "edit" ? "Editar" : mode === "view" ? "Detalhes" : "Adicionar"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {mode === "view" ? (
          <div className="info-modal-content">
            <p>
              <strong>Nome:</strong> {formData.name}
            </p>
            <p>
              <strong>Professor:</strong> {formData.teacher}
            </p>
            <p>
              <strong>Preço:</strong> {formData.price}
            </p>
            <p>
              <strong>Descrição:</strong> {formData.description}
            </p>
          </div>
        ) : (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter name"
                required
              />
            </Form.Group>
            <Form.Group controlId="formTeacher">
              <Form.Label>Teacher</Form.Label>
              <Form.Control
                type="text"
                name="teacher"
                value={formData.teacher}
                onChange={handleChange}
                placeholder="Enter teacher"
                required
              />
            </Form.Group>
            <Form.Group controlId="formPrice">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                name="price"
                value={formData.price}
                onChange={handleChange}
                placeholder="Enter price"
                required
              />
            </Form.Group>
            <Form.Group controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder="Enter description"
                rows={3}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Save
            </Button>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ExtraLessonModal;
